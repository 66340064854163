<template>
  <div>
    <div class="columns is-gapless">
      <div class="column">
        <div class="field">
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field-body">
                <div class="field has-addons">
                  <div class="control has-icons-left has-icons-right">
                    <input class="input"
                      v-model="filter.query"
                      type="text"
                      placeholder="Start typing to filter"
                      @input="search">
                    <span class="icon is-left">
                      <i class="mdi mdi-magnify mdi-18px" />
                    </span>
                  </div>
                  <div class="control">
                    <a class="button is-primary is-outlined tooltip"
                      data-tooltip="Reset search"
                      @click="reset()">
                      <span class="icon">
                        <i class="mdi mdi-close mdi-24px" />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <div class="control is-pulled-right">
            <div class="select"
              @change="getAvailableMiscs()">
              <select v-model="activeTab">
                <option :value="labourType.toUpperCase() === availableMiscTypes.emta.toUpperCase() ? availableMiscTypes.emta : availableMiscTypes.standard">Standard</option>
                <option :value="availableMiscTypes.custom">Custom</option>
                <option :value="availableMiscTypes.emtasundry">e-MTA Sundry</option>
                <option v-if="$userInfo && $userInfo.countryCode.localeCompare('NZ', $userInfo.locale, {sensitivity: 'accent'}) === 0"
                  :value="availableMiscTypes.veronz">Vero-NZ</option>
              </select>
            </div>
          </div>
        </div>
        <!-- <div class="tabs is-toggle is-pulled-right">
          <ul>
            <li :class="{ 'is-active' : activeTab === availableMiscTypes.standard || activeTab === availableMiscTypes.emta }">
              <a @click="tabClicked(availableMiscTypes.standard)">
                <span>Standard</span>
              </a>
            </li>
            <li :class="{ 'is-active' : activeTab === availableMiscTypes.emtasundry }">
              <a @click="tabClicked(availableMiscTypes.emtasundry)">
                <span>e-MTA</span>
              </a>
            </li>
            <li v-if="$userInfo && $userInfo.countryCode.localeCompare('NZ', $userInfo.locale, {sensitivity: 'accent'}) === 0"
              :class="{ 'is-active' : activeTab === availableMiscTypes.veronz }">
              <a @click="tabClicked(availableMiscTypes.veronz)">
                <span>Vero-NZ</span>
              </a>
            </li>
          </ul>
        </div> -->
      </div>
    </div>
    <div class="table-container-avaialble-butterfly-items">
      <bulma-table class="table is-striped is-narrow is-fullwidth"
        :columns="columns"
        :page-index="filter.pageIndex"
        :page-size="filter.pageSize"
        :total-rows="totalRows"
        :is-loading="isTableLoading"
        :sort-column="filter.sortColumn"
        :sort-order="filter.sortOrder"
        @pageChanged="onPageChange"
        @sort="sort">
        <tr v-for="(item, index) in availableItems"
          :key="index"
          @dblclick="addNewItem(item, index)">
          <td>{{ item.itemNo }}</td>
          <td>{{ item.description }}</td>

          <td class="has-text-right mr-2"
            @click="addNewItem(item, index)">
            <div class="butterfly-item-values is-flex is-justify-content-center"
              :class="{ ' has-text-weight-bold is-selected': getExistingItem(item)}">
              {{ item.price | formatNumber($userInfo.locale) }}
            </div>
          </td>

          <!-- <td class="has-vertical-middle has-text-centered is-content-width">
            <a class="button is-small is-inverted"
              :class="getExistingItem(item) ? 'is-danger' : 'is-primary'"
              @click="addNewItem(item, index)">
              <span class="icon is-medium">
                <i class="mdi mdi-24px"
                  :class="getExistingItem(item) ? 'mdi-minus' : 'mdi-plus'" />
              </span>
            </a>
          </td> -->
        </tr>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <span icon="icon is-large">
                <i class="mdi mdi-48px mdi-emoticon-sad" />
              </span>
              <p>Nothing</p>
            </div>
          </section>
        </template>
      </bulma-table>
    </div>
  </div>
</template>

<script>
import QuoteService from '../QuoteService'
import { AvailableMiscTypes } from '../QuoteService'
import { AvailableMiscColumns } from '../columns'
import { ItemCategoryTypes, LabourTimeTypes } from '@/enums'
import BulmaTable from '@/components/BulmaTable'
import _debounce from 'lodash.debounce'
// import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import Guid from '@/components/Guid'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { QuoteItemModel } from '@/classes/viewmodels'

export default {
  name: 'AvailableMiscs',
  components: {
    BulmaTable
  },
  filters: {},
  mixins: [NumberFiltersMixin],
  props: {
    value: {
      type: Array,
      default: function () {
        return []
      }
    },
    quoteId: {
      type: String,
      default: ''
    },
    labourType: {
      type: String,
      default: ''
    },
    nextLineNumber: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 8
    }
  },
  data() {
    return {
      innerValue: null,
      selectedRow: null,
      isTableLoading: false,
      totalRows: 0,
      filter: {
        countryId: Guid.empty(),
        query: '',
        type: 'standard',
        sortColumn: '',
        sortOrder: '',
        pageIndex: 1,
        pageSize: this.pageSize
      },
      availableItems: null,
      selectedItems: [],
      activeTab: '',
      preActiveTab: ''
    }
  },
  computed: {
    columns() {
      return AvailableMiscColumns
    },
    newItems() {
      return this.value.filter((i) => i.isNew && !i.deleted)
    },
    availableMiscTypes() {
      return AvailableMiscTypes
    }
  },
  created() {
    this.activeTab = this.labourType === LabourTimeTypes.eMTA ? this.availableMiscTypes.emta : this.availableMiscTypes.standard
    this.innerValue = this.value
    this.getAvailableMiscs()
  },
  mounted() {},
  methods: {
    async getAvailableMiscs() {
      this.isTableLoading = true

      if (this.activeTab === AvailableMiscTypes.custom) {
        this.filter.type = this.activeTab
      }

      if (this.activeTab === AvailableMiscTypes.standard) {
        this.filter.type = `${AvailableMiscTypes.standard}, ${AvailableMiscTypes.custom}`
      }

      if (this.preActiveTab !== this.activeTab) {
        this.filter.pageIndex = 1
        this.preActiveTab = this.activeTab
      }

      this.availableItems = await QuoteService.getAvailableMiscItems(this.filter, this.activeTab)
      this.totalRows = this.availableItems.length > 0 ? this.availableItems[0].totalRows : 0
      this.isTableLoading = false
    },
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.getAvailableMiscs()
    },
    sort(name, order) {},
    reset() {
      this.selectedItems = []
      this.filter.query = ''
      this.type = 'standard'
      this.filter.pageIndex = 1
      this.getAvailableMiscs()
    },
    search: _debounce(function () {
      this.filter.pageIndex = 1
      this.getAvailableMiscs()
    }, 500),
    cancel(value) {
      this.selectedItems = value
    },

    getExistingItem(item) {
      return this.value.find((i) => i.itemDesc === item.description && i.itemNo === item.itemNo && !i.deleted && !i.deleteOtherItem)
    },

    addNewItem(item, index) {
      const existingItem = this.getExistingItem(item)
      if (!existingItem) {
        let newItem = new QuoteItemModel(this.quoteId, item.itemNo, item.description, ItemCategoryTypes.MISC)
        newItem.lineNumber = this.nextLineNumber
        newItem.value = item.price
        newItem.side = item.side
        newItem.sortNo = this.value.length ? Math.max(...this.value.map((i) => i.sortNo)) + 1 : 1
        this.$emit('item-added', newItem)
      } else {
        this.$eventHub.$emit('deleteMisc', existingItem)
        // this.$notification.openNotificationWithType('warning', 'Misc', 'Item is already in selected list. Item not added.')
      }
    }
    // tabClicked(miscType) {
    //   if (miscType === this.availableMiscTypes.standard && this.labourType.toUpperCase() === this.availableMiscTypes.emta.toUpperCase()) {
    //     this.activeTab = this.availableMiscTypes.emta
    //   } else {
    //     this.activeTab = miscType
    //   }
    //   this.getAvailableMiscs()
    // }
  }
}
</script>

<style lang="scss" scoped>
.svg path {
  fill: red !important;
  &:hover path {
    fill: white !important;
  }
}
</style>
