<template>
  <div v-if="isValidationComplete"
    class="reset-password-container
    is-flex is-align-items-center is-justify-content-center">
    <div class="box">
      <div class="control mb-5 is-flex is-justify-content-center">
        <img src="@/assets/images/flexiquote-logo.svg"
          style="max-height: 2.25rem"
          alt="Flexiquote Logo"
          title="Flexiquote Logo">
      </div>

      <div class="control mb-6">
        <h4 class="has-text-weight-medium is-size-5">Set new password</h4>
        <p class="has-text-grey is-size-7">
          Must be at least 8 characters, have at least one symbol, one number, one uppercase and one lowercase letter.
        </p>
      </div>

      <div v-if="!isResetComplete && !hasValidationError">
        <div class="control mb-6">
          <div class="field">
            <label class="label is-required-label">New Password</label>
            <div class="field has-addons">
              <div class="control is-expanded">
                <input class="input"
                  :type="newPasswordVisible ? 'text' : 'password'"
                  v-model="newPassword"
                  @blur="validateNewPassword"
                  placeholder="New Password">
                <span v-if="$v.newPassword.$dirty && !$v.newPassword.required"
                  class="help is-danger ml-1">Required</span>
                <span v-if="$v.newPassword.$dirty && !$v.newPassword.validatedNewPassword
                        && $v.newPassword.required"
                  class="help is-danger ml-1">
                  {{ passwordValidationMessage }}
                </span>
              </div>
              <p class="control">
                <button class="button"
                  @click="newPasswordVisible = !newPasswordVisible">
                  <span class="icon is-small is-right">
                    <i class="mdi mdi-18px"
                      :class="newPasswordVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'" />
                  </span>
                </button>
              </p>
            </div>
          </div>

          <div class="field">
            <label class="label is-required-label">Confirm New Password</label>
            <div class="field has-addons">
              <div class="control is-expanded">
                <input class="input"
                  :type="confirmedPasswordVisible ? 'text' : 'password'"
                  v-model="confirmedPassword"
                  @blur="validateConfirmedPassword"
                  placeholder="Confirm New Password">
                <span v-if="$v.confirmedPassword.$dirty && !$v.confirmedPassword.required"
                  class="help is-danger ml-1">Required</span>
                <span v-if="$v.confirmedPassword.$dirty && !$v.confirmedPassword.matchNewPassword
                        && $v.confirmedPassword.required"
                  class="help is-danger ml-1">Please make sure your passwords match.</span>
              </div>
              <p class="control">
                <button class="button"
                  @click="confirmedPasswordVisible = !confirmedPasswordVisible">
                  <span class="icon is-small is-right">
                    <i class="mdi mdi-18px"
                      :class="confirmedPasswordVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'" />
                  </span>
                </button>
              </p>
            </div>
          </div>
        </div>

        <div class="control mb-2 is-flex is-justify-content-end">
          <button class="button is-primary"
            @click="forgotPasswordReset">Confirm</button>
        </div>
      </div>

      <div v-if="isResetComplete || (isValidationComplete && hasValidationError)">
        <notification-box class="mb-6"
          :type="notificationType">
          <p class="is-size-7"
            slot="text-content">{{ message }}</p>
        </notification-box>

        <div class="control is-flex is-justify-content-end">
          <button class="button is-primary"
            @click="cancel">
            <span class="icon mr-1">
              <i class="mdi mdi-18px mdi-arrow-left" />
            </span>
            Back to login
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AuthService } from '@/services'
import { getErrorMessage } from './ErrorMessage'
import NotificationBox from '@/components/Notification/NotificationBox.vue'
import { NotificationBoxType } from '@/enums'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ResetPasword',
  components: { NotificationBox },
  data() {
    return {
      confirmedPasswordVisible: false,
      confirmedPassword: '',
      isResetComplete: false,
      isValidationComplete: false,
      hasResetError: false,
      hasValidationError: false,
      message: '',
      newPassword: '',
      newPasswordVisible: false,
      notificationType: '',
      passwordValidationMessage: '',
      refToken: '',
      token:''
    }
  },
  created() {
    this.token = this.$route.query.token
    this.refToken = this.$route.query.refToken
    this.isPasswordResetAllowed()
  },
  methods: {
    cancel() {
      window.location.href = process.env.VUE_APP_ROOT_URI + '/SignIn.aspx'
    },

    async forgotPasswordReset() {
      this.$v.$touch()
      if (this.$v.$error) return
      try {
        await AuthService.forgotPasswordReset(this.token, this.refToken, this.confirmedPassword)
        this.message = 'Your password has been successfully reset. Please try logging again.'
        this.notificationType = NotificationBoxType.Success
      } catch (e) {
        this.hasResetError = true
        this.notificationType = NotificationBoxType.Error
        this.message = getErrorMessage(e.response.data.message).message
      } finally {
        this.isResetComplete = true
      }
    },

    async isPasswordResetAllowed () {
      try {
        await AuthService.CheckPasswordResetEligibility(this.token)
      } catch (e) {
        this.hasValidationError = true
        this.notificationType = NotificationBoxType.Error
        this.message = getErrorMessage(e.response.data.message).message
      } finally {
        this.isValidationComplete = true
      }
    },

    validateConfirmedPassword() {
      this.$v.confirmedPassword.$touch()
    },

    validateNewPassword() {
      this.$v.newPassword.$touch()
    }
  },

  validations: {
    newPassword: {
      required,
      validatedNewPassword(value) {
        if (value.length < 8) {
          this.passwordValidationMessage = 'Password must be at least 8 characters long'
          return false
        }
        else if (!/\d/.test(value)) {
          this.passwordValidationMessage = 'Password must have at least one number'
          return false
        }
        else if (!/[A-Z]/.test(value)) {
          this.passwordValidationMessage = 'Password must have at least one uppercase character'
          return false
        }
        else if (!/[a-z]/.test(value)) {
          this.passwordValidationMessage = 'Password must have at least one lowercase character'
          return false
        }
        else if (!/[!@#$%^&*()_+=\[\]{};:<>|./?,-]/.test(value)) {
          this.passwordValidationMessage = 'Password must have at least one special character'
          return false
        }
        return true
      }
    },
    confirmedPassword: {
      required,
      matchNewPassword(value) {
        return value == this.newPassword
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.reset-password-container {
  width: 100%;
  height: 100vh;
  background: url(../../assets/images/flexiquoteloginbackground.jpg) top center no-repeat !important;
  background-size: cover !important;
}
.box {
  width: 400px;
}
.label {
  font-weight: 500;
}
</style>