<template>
  <base-modal-ex v-if="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="70"
    style="top: 50px"
    has-modal-card>
    <header class="modal-card-head">
      <p class="modal-card-title"
        style="font-weight: bold; font-size: 16px;"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <span class="is-size-4">Edit Suppliers - Price Parts</span>
      </p>

      <div class="is-flex is-align-items-center mr-3 is-font-weight-heavy has-text-primary is-size-5"
        :class="{'has-text-danger': selectedSuppliers.length >= maxSuppliersCount}">
        Selected Suppliers: {{ selectedSuppliers.length }} / {{ isNormal ? maxSuppliersCount : 1 }}
      </div>

    </header>
    <section class="modal-card-body"
      style="height: 78vh">
      <div class="table-container-scroll table-container"
        style="height: 25vh;">

        <div class="is-flex is-flex-col">
          <div class="is-flex"
            style="width: 100%">

            <div style="width: 4%; padding: 5px;">
              <div v-if="isNormal"
                class="pretty p-icon p-curve p-smooth m-0 p-bigger">
                <input type="checkbox"
                  v-model="isTitleCheck"
                  @change="titleCheckboxChanged(isTitleCheck)">
                <div class="state">
                  <i class="icon mdi mdi-check" />
                  <label />
                </div>
              </div>
            </div>

            <div class="supplier-header"
              style="width: 30%;">
              Preferred Suppliers
            </div>

            <div class="supplier-header has-text-left"
              style="width: 12%;">
              Type
            </div>

            <div class="supplier-header"
              style="width: 20%;">
              Location
            </div>

            <div class="supplier-header"
              style="width: 12%;">
              Rating
            </div>

            <div class="supplier-header"
              style="width: 10%;">
              Status
            </div>

            <div class="supplier-header"
              style="width: 12%; ">
              Remove preferred
            </div>

          </div>
        </div>

        <div v-for="(item) in preferredSuppliersClone"
          :key="item.id">
          <div class="is-flex is-flex-col">

            <div class="is-flex table-row"
              style="width: 100%">

              <div style="width: 4%; padding: 5px;">
                <div v-if="isNormal"
                  class="pretty p-icon p-curve p-smooth m-0 p-bigger">
                  <input type="checkbox"
                    v-model="item.isChecked"
                    :checked="true"
                    @change="selectSupplier(item)">
                  <div class="state">
                    <i class="icon mdi mdi-check" />
                    <label />
                  </div>
                </div>
                <div v-if="!isNormal"
                  class="pretty p-icon p-round p-smooth is-size-10">
                  <input v-model="item.isChecked"
                    name="select-supplier"
                    :id="'radio-' + item.id"
                    :value="true"
                    @change="selectSupplierDirectOrder(item)"
                    type="radio">
                  <div class="state">
                    <i class="icon mdi mdi-check" />
                    <label />
                  </div>
                </div>
              </div>

              <div class=""
                style="width: 30%; padding: 5px;">
                {{ item.name }}
              </div>

              <div class=""
                style="width: 12%; padding: 5px;">
                {{ item.supplierType }}
              </div>

              <div class=""
                style="width: 20%; padding: 5px;">
                {{ item.suburb + ' ' + item.postcode }}
              </div>
              <div class=""
                style="width: 12%; padding: 5px;">
                <a v-for="(i,rating) in 5"
                  :key="rating"
                  class="icon has-text-grey-light is-small is-inverted">
                  <span :class="{'icon has-text-primary': i <= item.rating}">
                    <i class="mdi mdi-star" />
                  </span>
                </a>
              </div>
              <div class=""
                style="width: 10%; padding: 5px;">
                {{ item.isLocked === 1 ? 'Locked' : 'Quoting' }}
              </div>

              <div class="has-text-centered"
                style="width: 12%; padding: 5px;">
                <a class="button is-warning is-small is-inverted tooltip"
                  @click.prevent="removeFromPreferred(item, $event)"
                  data-tooltip="Remove from Preferred Suppliers list">
                  <span class="icon is-medium">
                    <i class="mdi mdi-star mdi-20px" />
                  </span>
                </a>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="field-body"
        style="max-height: 25vh;">
        <div class="field">
          <label class="label mt-1">Suggested Supplier</label>
        </div>
        <div class="field">
          <div class="pretty p-icon p-curve p-smooth p-bigger">
            <input type="checkbox"
              v-model="showSpecialSuppliers">
            <div class="state p-primary">
              <label style="font-weight: bold">Show Only Specialist Suppliers</label>
              <i class="icon mdi mdi-check" />
            </div>
          </div>
        </div>
        <div class="field is-horizontal is-justify-content-flex-end">
          <label class="label mr-2 mt-1">Filter by State</label>
          <div class="select">
            <select class="select"
              style="background-color: #dfe3e6de; height:30px"
              v-model="selectedStateId"
              @change="changeState()">
              <option v-for="(state) in states"
                :key="state.id"
                :value="state.id">
                {{ state.nameShort }}
              </option>
            </select>
          </div>
        </div>
        <div class="has-text-left control has-icons-right">
          <input class="input"
            v-model="filter.query"
            style="background-color: #dfe3e6de; height:30px"
            type="text"
            placeholder="Search Suppliers">
          <span class="icon is-right">
            <i style="color: #525a61de;"
              class="mdi mdi-magnify mdi-18px" />
          </span>
        </div>
      </div>

      <div class="field-body">
        <div class="tabs mt-2">
          <ul class="mr-5"
            style="padding: 5px; font-weight: bold; font-size: 14px">
            <li class="mr-6"
              :class="{ 'is-active' : activeTab === 'OEM' }">
              <a @click="tabClicked('OEM')">OEM Dealers
                <span class="has-badge-info has-badge-rounded has-badge-medium ml-4"
                  :data-badge="supplierCounts.oemCount" /></a>
            </li>
            <li class="mr-6"
              :class="{ 'is-active' : activeTab === 'Aftermarket' }">
              <a @click="tabClicked('Aftermarket')">
                Aftermarket/Parallel
                <span class="has-badge-info has-badge-rounded has-badge-medium ml-4"
                  :data-badge="supplierCounts.aftCount" /></a>
            </li>
            <li class="mr-6"
              :class="{ 'is-active' : activeTab === 'Used' }">
              <a @click="tabClicked('Used')">Used
                <span class="has-badge-info has-badge-rounded has-badge-medium ml-4"
                  :data-badge="supplierCounts.usedCount" /></a>
            </li>
            <li class="mr-6"
              :class="{ 'is-active' : activeTab === 'Reconditioned' }">
              <a @click="tabClicked('Reconditioned')">Reconditioned
                <span class="has-badge-info has-badge-rounded has-badge-medium ml-3"
                  :data-badge="supplierCounts.recndCount" /></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="table-container-scroll table-container">
        <parts-check-aftermarket-suppliers-modal v-if="isAftermarketSuppliers"
          v-model="filteredSuppliers.activeOtherSuppliers"
          :vehicle-make-id="vehicleMakeId"
          :supplier-type="activeTab"
          :is-normal="isNormal"
          @selectSupplierDirectOrder="selectSupplierDirectOrder"
          @add-to-preferred="addToPreferred"
          @select-supplier="selectSupplier" />
        <parts-check-oem-suppliers-modal v-if="isOemSuppliers"
          v-model="filteredSuppliers.activeOtherSuppliers"
          :vehicle-make-id="vehicleMakeId"
          :supplier-type="activeTab"
          :is-normal="isNormal"
          @selectSupplierDirectOrder="selectSupplierDirectOrder"
          @add-to-preferred="addToPreferred"
          @select-oem-supplier="selectSupplier" />
        <parts-check-reconditioned-suppliers-modal v-if="isReconditionedSuppliers"
          v-model="filteredSuppliers.activeOtherSuppliers"
          :vehicle-make-id="vehicleMakeId"
          :supplier-type="activeTab"
          :is-normal="isNormal"
          @add-to-preferred="addToPreferred"
          @selectSupplierDirectOrder="selectSupplierDirectOrder"
          @select-supplier="selectSupplier" />
        <parts-check-used-suppliers-modal v-if="isUsedSuppliers"
          v-model="filteredSuppliers.activeOtherSuppliers"
          :vehicle-make-id="vehicleMakeId"
          :supplier-type="activeTab"
          :is-normal="isNormal"
          @add-to-preferred="addToPreferred"
          @selectSupplierDirectOrder="selectSupplierDirectOrder"
          @select-supplier="selectSupplier" />
      </div>
    </section>

    <footer class="modal-card-foot is-justify-content-end">
      <div class="is-flex is-justify-content-flex-end">

        <div class="is-flex is-justify-content-flex-end">
          <button class="button is-danger "
            @click="close()">
            Cancel
          </button>
        </div>

        <div class="ml-2">
          <button class="button is-primary"
            @click="closeAndSave()">
            Save
          </button>
        </div>

      </div>

    </footer>
  </base-modal-ex>
</template>
<script>
import { BaseModalEx } from '@/components/BulmaModal'
import { PreferredSupplierColumns, SuggestedSupplierColumns, PreferredSupplierDirectOrderColumns } from '../../columns'
import PartsCheckUsedSuppliersModal from './PartsCheckUsedSuppliersModal'
import PartsCheckAftermarketSuppliersModal from './PartsCheckAftermarketSuppliersModal'
import PartsCheckOemSuppliersModal from './PartsCheckOemSuppliersModal'
import PartsCheckReconditionedSuppliersModal from './PartsCheckReconditionedSuppliersModal'
import cloneDeep from 'lodash.clonedeep'

export default {
  name: 'PartsCheckSuppliersModal',
  components: {
    BaseModalEx,
    PartsCheckAftermarketSuppliersModal,
    PartsCheckOemSuppliersModal,
    PartsCheckReconditionedSuppliersModal,
    PartsCheckUsedSuppliersModal
  },
  props: {
    maxSuppliersCount: {
      type: Number,
      default: 10
    },
    value: null,
    vehicle: null,
    states: null,
    stateId: null,
    vehicleMakeId: null,
    preferredSuppliers: null,
    isNormal: true,
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end'
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-file-export'
    },
    enableAttach: {
      type: Boolean,
      default: true
    },
    defaultEmail: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isActive: false,
      isTitleCheck: false,
      selectedStateId: '',
      activeTab: '',
      innerValue: null,
      serachSupplierName: null,
      preferredSuppliersClone: null,

      selectedSuppliers: [],
      filter: { query: '' },
      showSpecialSuppliers: false
    }
  },
  computed: {
    preferredSupplierColumns() {
      return PreferredSupplierColumns
    },
    preferredSupplierDirectOrderColumns() {
      return PreferredSupplierDirectOrderColumns
    },
    suggestedSupplierColumns() {
      return SuggestedSupplierColumns
    },
    isOemSuppliers() {
      return this.activeTab === 'OEM'
    },
    isAftermarketSuppliers() {
      return this.activeTab === 'Aftermarket'
    },
    isUsedSuppliers() {
      return this.activeTab === 'Used'
    },
    isReconditionedSuppliers() {
      return this.activeTab === 'Reconditioned'
    },
    filteredSuppliers() {
      let activeOtherSuppliers = []

      if (this.showSpecialSuppliers) {
        activeOtherSuppliers = this.innerValue.filter((i) => i.isPreferred != '1' && i.isSpecial == '1')
      } else {
        activeOtherSuppliers = this.innerValue.filter((i) => i.isPreferred != '1' && i.isSpecial != '1')
      }
      if (this.filter.query !== '') {
        let query = this.filter.query.length > 0 ? this.filter.query.toLowerCase().trim() : this.filter.query

        if (query !== '') {
          activeOtherSuppliers = activeOtherSuppliers.filter((element) => {
            return element.name.toLowerCase().indexOf(query) !== -1
          })
        }
      }
      return { activeOtherSuppliers }
    },
    // Counts of suppliers for tabs
    supplierCounts() {
      return {
        oemCount: this.filteredSuppliers.activeOtherSuppliers.filter((i) => i.supplierTypeId === '3').length,
        aftCount: this.filteredSuppliers.activeOtherSuppliers.filter((i) => i.supplierTypeId === '4').length,
        usedCount: this.filteredSuppliers.activeOtherSuppliers.filter((i) => i.supplierTypeId === '5').length,
        recndCount: this.filteredSuppliers.activeOtherSuppliers.filter((i) => i.supplierTypeId === '6').length
      }
    }
  },
  watch: {
    selectedStateId: function (newVal, oldVal) {
      this.otherSuppliers = this.value.filter((s) => s.stateId == newVal)
    },
    value: {
      handler: function (newVal, oldVal) {
        this.innerValue = cloneDeep(newVal)
      },
      deep: true
    },
    innerValue: {
      handler: function (newVal, oldVal) {
        if (!!newVal) this.getSelectedSuppliers()
      },
      deep: true
    },
    preferredSuppliersClone: {
      handler: function (newVal, oldVal) {
        this.getSelectedSuppliers()
      },
      deep: true
    }
    // preferredSuppliers: {
    //   handler: function (newVal, oldVal) {
    //     this.preferredSuppliersClone = cloneDeep(newVal)
    //   },
    //   deep: true
    // }
  },
  created() {
    this.innerValue = cloneDeep(this.value)
    this.activeTab = 'OEM'
    this.selectedStateId = this.stateId
    this.preferredSuppliersClone = cloneDeep(this.preferredSuppliers)
    // TODO: this might be wrong, you can only have one oem selected, so if preffered has more than one
    // TODO: then the tickbox will not be ticked even if all are ticked
    let found = this.preferredSuppliers.find((id) => id.isChecked === false)
    if (found) this.isTitleCheck = false
    else this.isTitleCheck = true
    this.getSelectedSuppliers()
  },
  mounted() {
    this.isActive = this.active || false
  },
  methods: {
    addToPreferred(supplierObj) {
      if (this.preferredSuppliersClone.length >= this.maxSuppliersCount) {
        // this.$toast.open({
        //   message: 'You can only add up to ' + this.maxSuppliersCount + ' Preferred Suppliers',
        //   type: 'is-danger',
        //   position: 'is-top',
        //   queue: false
        // })
      this.$notification.error('','You can only add up to ' + this.maxSuppliersCount + ' Preferred Suppliers')

        return
      }

      // dont limit oem supplier to one
      // const hasOemSupplier = this.preferredSuppliersClone.find((i) => i.supplierTypeId === '3')
      // if (supplierObj.supplierTypeId === '3' && !!hasOemSupplier) {
      //   this.$toast.open({
      //     message: 'You can only select one OEM Supplier',
      //     type: 'is-danger',
      //     position: 'is-bottom',
      //     queue: false
      //   })
      //   return
      // }

      if (!!supplierObj) {
        supplierObj.isPreferred = '1'
        this.preferredSuppliersClone.push(cloneDeep(supplierObj))
      }
    },

    getSelectedSuppliers() {
      this.selectedSuppliers = []
      this.preferredSuppliersClone.forEach((i) => {
        if (i.isChecked) this.selectedSuppliers.push(i)
      })
      this.innerValue.forEach((i) => {
        if (i.isChecked) this.selectedSuppliers.push(i)
      })
    },
    closeAndSave() {
      this.isActive = false
      this.$emit('selected-other-supplier-list', this.selectedSuppliers)
      this.$emit('update-preferred-suppliers', this.preferredSuppliersClone)
      this.$emit('update:active', false)
    },
    close() {
      this.isActive = false
      this.$emit('update:active', false)
    },
    removeFromPreferred(item, event) {
      let found = this.innerValue.find((i) => i.id === item.id)
      if (found) {
        found.isPreferred = '0'
        this.preferredSuppliersClone = this.preferredSuppliersClone.filter((i) => i.id !== item.id)
      } else {
        this.preferredSuppliersClone = this.preferredSuppliersClone.filter((i) => i.id !== item.id)
        const cloned = cloneDeep(item)
        cloned.isPreferred = '0'
        this.innerValue.push(cloneDeep(cloned))
      }
      // await PartsCheckService.removeFromPreferredSuppliersList(id, this.vehicleMakeId)
    },
    titleCheckboxChanged(value) {
      // return
      this.preferredSuppliersClone.forEach((i) => (i.isChecked = value))
      if (value) {
        this.preferredSuppliersClone.filter((i) => i.supplierTypeId !== '3').forEach((i) => (i.isChecked = true))
        this.preferredSuppliersClone.filter((i) => i.supplierTypeId === '3').forEach((i) => (i.isChecked = false))
        let found = this.preferredSuppliersClone.find((i) => i.supplierTypeId === '3')
        console.log(found)
        if (found) found.isChecked = true
      }
    },
    tabClicked(type) {
      this.activeTab = type
    },

    changeState() {
      this.getPartsCheckSuppliersPerState(this.serachSupplierName)
    },
    async getPartsCheckSuppliersPerState(serachName) {
      this.$emit('changeState', this.selectedStateId)
    },
    refreshOtherSuppliersList() {
      this.$emit('refresh-suppliers-list')
    },

    selectSupplierDirectOrder(item) {
      // unset all other suppliers except the selected one
      this.preferredSuppliersClone.forEach((i) => {
        if (i.id === item.id) {
          i.isChecked = true
        } else {
          i.isChecked = false
        }
      })
      this.innerValue.forEach((i) => {
        if (i.id === item.id) {
          i.isChecked = true
        } else {
          i.isChecked = false
        }
      })
    },

    selectSupplier(item) {
      console.log('here')

      // check if max suppliers count is reached
      if (this.selectedSuppliers.length >= this.maxSuppliersCount && item.isChecked) {
        // this.$toast.open({
        //   message: 'You can only select up to ' + this.maxSuppliersCount + ' Suppliers',
        //   type: 'is-danger',
        //   position: 'is-top',
        //   queue: false
        // })
      this.$notification.error('','You can only select up to ' + this.maxSuppliersCount + ' Suppliers')

        this.$nextTick(() => {
          item.isChecked = false
        })
        return
      }

      // check if oem supplier is already selected if item is oem
      if (item.supplierTypeId == '3' && item.isChecked) {
        // find and uncheck the oem supplier
        const oemSupplierAlreadySelected = this.selectedSuppliers.find((i) => i.supplierTypeId == '3')
        if (!!oemSupplierAlreadySelected) oemSupplierAlreadySelected.isChecked = false

        if (oemSupplierAlreadySelected) {
          // this.$toast.open({
          //   message: 'Previous OEM supplier has been unselected',
          //   type: 'is-warning',
          //   position: 'is-top',
          //   queue: false
          // })
      this.$notification.warning('','Previous OEM supplier has been unselected')

        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
.table-container {
  max-height: 35vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.table-row {
  display: flex;
  background-color: white;
  border: 1px solid #dce1e5;
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 5px !important;
  font-size: 14px;
}
.custom-delete {
  background: none;
  border: none;
}
.supplier-header {
  padding: 5px;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}
</style>
