<template>
  <quote-parts-control-section :key="purchaseOrder.purchaseOrderId">

    <template slot="heading">
      <div class="is-flex"
        :class="{'disabled': isDisabled}">
        <div class="is-flex is-flex-direction-column">
          <span class="has-text-weight-bold">
            {{ purchaseOrder.vendor.name }} - Total: {{ $filters.formatCurrency(addTotal(getPurchaseOrderLines(purchaseOrder), 'unitPrice') , $userInfo.locale) }}
          </span>
          <div class="is-flex is-align-items-center">
            <span :class="{'has-text-success': purchaseOrder.partsCheckPurchaseOrderNo !== ''}">
              {{ purchaseOrder.partsCheckPurchaseOrderNo == "" ? 'PO No. ' : 'PC PO No.' }}
            </span>
            <span class="is-underlined has-text-primary mx-1"
              v-tippy="{ enabled: true, content: purchaseOrder.partsCheckPurchaseOrderNo != '' ? 'Go to PartsCheck' : 'Manage this PO' }"
              :class="{'is-underlined has-text-success is-clickable': purchaseOrder.partsCheckPurchaseOrderNo !== ''}"
              @click="purchaseOrder.partsCheckPurchaseOrderNo !== '' ? goToPartsCheck(purchaseOrder) : goToPurchaseOrderManagement(purchaseOrder)">
              {{ purchaseOrder.partsCheckPurchaseOrderNo != "" ? purchaseOrder.partsCheckPurchaseOrderNo : purchaseOrder.purchaseOrderNo }}
            </span>
            <span>Ordered {{ purchaseOrder.purchaseOrderDate | formatDate($userInfo.locale) }}</span>

            <!-------------------------------------- Print PO && Returns ------------------------------------------->
            <div v-if="!!purchaseOrder">
              <div class="ml-2 is-align-items-center is-flex has-background-white-ter px-2 is-clickable">
                <span class="mdi mdi-file-document-outline mdi-20px mr-1" />
                <span class="">Print</span>
              </div>
              <tippy arrow
                :interactive="true"
                placement="top"
                :theme="`light`"
                :trigger="`click`">

                <div style="min-width: 500px;"
                  class="mb-1">
                  <div class="left">
                    <span class="has-text-weight-bold ml-1">
                      Purchase Order
                    </span>
                  </div>
                </div>

                <div class="is flex is-flex-row ">
                  <div class="w-100 part-row is-flex is-flex-row">
                    <div style="width: 80%;"
                      class="left">
                      <span class="ml-2">
                        {{ purchaseOrder.purchaseOrderNo }}
                      </span>
                    </div>
                    <div style="width: 20%;"
                      class="right">
                      <div class="button mx-2 is-primary is-outlined px-2"
                        @click="print">
                        View
                      </div>
                    </div>
                  </div>
                </div>

                <div style="min-width: 500px;"
                  class="mb-1">
                  <div class="left">
                    <span class="has-text-weight-bold ml-1">
                      Return For Credit
                    </span>
                  </div>
                </div>
                <div class="is flex is-flex-row ">
                  <div class="w-100 part-row is-flex is-flex-row"
                    v-for="(item, index) in returnOrders"
                    :key="item.returnNoRef + index">
                    <div style="width: 80%;"
                      class="left">
                      <span class="ml-2">
                        {{ item.returnNoRef }}
                      </span>
                    </div>
                    <div style="width: 20%;"
                      class="right">
                      <div class="button mx-2 is-primary is-outlined px-2"
                        :id="`print-return-order` + item.returnNoRef"
                        @click="print1(item.returnNoRef)">
                        View
                      </div>
                    </div>
                  </div>
                </div>
              </tippy>
            </div>

            <!---------------------------------------------- Order receipts ------------------------>
            <div v-if="!!purchaseOrder.orderReceipts?.length">
              <div style="cursor: pointer;"
                class="ml-2 is-align-items-center is-flex has-background-white-ter px-2 is-clickable"
                v-tippy="{content: 'Invoices ' + purchaseOrder.orderReceipts?.length }">
                <span class="mdi mdi-file-document-outline mdi-20px mr-1" />
                <span class="">Invoices</span> <!-- {{ purchaseOrder.orderReceipts?.length }} -->
              </div>
              <tippy arrow
                :interactive="true"
                placement="top"
                :theme="`light`"
                :trigger="`click`">
                <div style="min-width: 500px;">
                  <div style="width: 80%;"
                    class="left">
                    <span class="has-text-weight-bold ml-1">
                      Invoices
                    </span>
                  </div>
                </div>
                <div class="is flex is-flex-row "
                  :title="isPartsCheckPurchaseOrder ? 'Parts Check Purchase Orders cannot be edited here' : ''">
                  <div class="w-100 part-row is-flex is-flex-row"
                    v-for="(item, index) in purchaseOrder.orderReceipts"
                    :key="item.invoiceNo + index">
                    <div style="width: 80%;"
                      class="left">
                      <span class="ml-2">
                        {{ item.invoiceNo }}
                      </span>
                    </div>
                    <div style="width: 20%;"
                      class="right">
                      <div class="button ml-2 is-primary is-outlined px-2"
                        @click="handleEditOrder(item)"
                        :id="`edit-invoice-` + item.invoiceNo">
                        View
                      </div>
                      <div class="button mx-2 is-primary is-outlined px-2"
                        :class="{ 'disabled': isPartsCheckPurchaseOrder }"
                        @click="handleReturnOrder(item)"
                        :id="`edit-invoice-` + item.invoiceNo">
                        Return
                      </div>
                    </div>
                  </div>
                </div>
              </tippy>
            </div>

          </div>
        </div>
      </div>
    </template>

    <template slot="heading_buttons"
      v-if="!isPartsCheckPurchaseOrder">
      <div class="button ml-2 is-primary"
        :id="`return-items-` + id"
        :class=" !isReturnItemsClickable ? 'disabled is-outlined' : 'is-primary'"
        @click="e => changeItemStatus(getPurchaseOrderLines(purchaseOrder), partsControlEnums.return.key, e)">
        Return Items
      </div>
      <div class="button ml-2 is-primary "
        :id="`recieve-items-` + id"
        :class=" !isReceiveItemsClickable ? 'disabled is-outlined' : 'is-primary'"
        @click="e => changeItemStatus(getPurchaseOrderLines(purchaseOrder), partsControlEnums.receive.key, e)">Receive Items</div>
      <div class="button ml-2 is-primary"
        :id="`cancel-items-` + id"
        :class=" !isCancelItemsClickable ? 'disabled is-outlined' : ' is-primary'"
        @click="e => changeItemStatus(getPurchaseOrderLines(purchaseOrder), partsControlEnums.cancel.key, e)">Cancel Items</div>
    </template>

    <template slot="empty">
      <section class="section">
        <div class="content has-text-grey has-text-centered">
          <span icon="icon is-large">
            <i class="mdi mdi-48px mdi-emoticon-sad" />
          </span>
          <p>Nothing</p>
        </div>
      </section>
    </template>

    <!----------------------------------------- Parts table section --------------------------------------->
    <template slot="body">
      <div class=" header parts-order-grid"
        :title="isPartsCheckPurchaseOrder ? 'Parts Check Purchase Orders cannot be edited here' : ''"
        v-if="viewMode === viewModeEnums.parts">
        <div class="center">#</div>

        <!-- this is the only way I could get checkbox to center :( -->
        <div class="checkbox-component is-clickable is-justify-content-center is-align-items-center mb-6 is-flex ml-1"
          @click="titleCheckboxChanged">
          <div class="pretty p-icon p-curve p-smooth"
            style="position: relative;">
            <input type="checkbox"
              :checked="areAllChecked"
              :id="`child-checkbox-` + id"
              :name="`child-checkbox-` + id"
              @click="titleCheckboxChanged">
            <div class="state">
              <i class="icon mdi mdi-check" />
              <label />
            </div>
          </div>
        </div>

        <div class="left">Description</div>
        <div>Part No</div>
        <div>Item Type</div>
        <div>Qty</div>
        <div>Ord</div>
        <div>Rec</div>
        <div>Ret</div>
        <div class="right">Unit Cost</div>
        <div class="right">Unit Sell</div>
        <div>Status</div>
      </div>

      <div class="is flex is-flex-row "
        :title="isPartsCheckPurchaseOrder ? 'Parts Check Purchase Orders cannot be edited here' : ''"
        v-if="viewMode === viewModeEnums.parts">
        <div :id="index + `-child-checkbox-` + id"
          v-for="(item, index) in purchaseOrderItems"
          :key="item.purchaseOrderItemId"
          class="part-row parts-order-grid"
          v-tippy="{
            content: 'Cancelled on' + ' ' + $filters.formatDateLocale(item.modifiedDate, $user.info.locale) ,
            theme: 'dark',
            enabled: !!item.deleted,
            arrow: true,
            interactive: true
          }"
          :class="{
            'item-deleted': item.deleted,
            'interactive': !isPartsCheckPurchaseOrder && !item.deleted
          }"
          @click="!item.deleted ? handleSelectItem(item) : null">

          <!-- line through when deleted -->
          <div class="line-through"
            v-if="!!item.deleted" />

          <div class="center">{{ index + 1 }}</div>

          <div>
            <div class="pretty p-icon p-curve p-smooth p-bigger mt-4">
              <input type="checkbox"
                :checked="selectedRows.includes(item.purchaseOrderItemId)"
                :id="`child-checkbox-` + index + '-' + id"
                :name="`child-checkbox-` + index + '-' + id">
              <div class="state">
                <i class="icon mdi mdi-check" />
                <label />
              </div>
            </div>
          </div>

          <div class="left">{{ item.description }}
            <span v-if="itemIsDeleted(item.quoteItemId)"
              class="has-text-danger tooltip"
              data-tooltip="Item is deleted in quote builder">&nbsp; Deleted
            </span>
          </div>
          <div>{{ item.partNo }}</div>
          <div>{{ item.mark }}</div>

          <div>{{ item.quotedItemQty }}</div>
          <div>{{ item.quantity }}</div>
          <div>{{ item.receivedQty }}</div>
          <div>{{ item.returnQty }}</div>
          <div class="right">{{ $filters.formatCurrency(item.unitPrice , $userInfo.locale) }}</div>
          <div class="right">{{ $filters.formatCurrency(item?.qtePrice , $userInfo.locale) }}</div>

          <div class="has-text-left">
            <div class="columns has-text-weight-bold"
              v-tippy="{ content: 'Go to PartsCheck', enabled: isPartsCheckPurchaseOrder }"
              @click="e => isPartsCheckPurchaseOrder ? goToPartsCheck(purchaseOrder, e) : null"
              :class="getItemStatus(item)?.colour"
              style="width: 100%;">
              <div class="column is-2 ml-3">
                <span :class="[getItemStatus(item)?.classStatus]" />
              </div>
              <div class="column is-10 is-justify-content-left">
                <span>{{ getItemStatus(item)?.title }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!---------------------------------------------- Receipt Section  ------------------------------------>

    </template>
  </quote-parts-control-section>
</template>

<script>
import QuotePartsControlSection from '../QuotePartsControlSection.vue'
import { customFormatDateLocale } from '@/utils/DateFunctions'
import { PartsCheckService } from '../../services'
import { KeyValuePairModel } from '@/classes/viewmodels'
import StoreMixin from './storeMixin'
import PartsControlService from '../PartsControlService'
import { Emailer } from '@/classes'
import { RemarkTypes, PartsControlActionTypes, QuoteItemsOrderStatusTypes } from '@/enums'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import ReturnPartsService from '@/views/returnparts/ReturnPartsService'

const viewModeEnums = {
  parts: 'parts',
  receipts: 'receipts'
}

export default {
  name: 'QuotePartsControlOrdered',
  components: { QuotePartsControlSection },
  filters: {
    formatDate: function (value, locale) {
      const options = {
        weekday: 'short',
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      }
      return customFormatDateLocale(value, locale, options)
    }
  },
  mixins: [StoreMixin],
  props: {
    value: null,
    purchaseOrder: {
      type: Object,
      default: () => ({})
    },
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      viewMode: viewModeEnums.parts,
      openSubRows: [],
      purchaseOrders: [],
      activePartsCheckRequest: 0,
      partsCheckPrices: [],
      selectedRows: [],

      // for return parts

      returnOrders: [],

      filter: {
        searchValue: this.purchaseOrder.purchaseOrderNo.toString(),
        dateFrom: '',
        dateTo: '',
        sortColumn: 'returnNoRef',
        sortOrder: 'desc',
        pageIndex: 1,
        pageSize: 18
      }
    }
  },
  computed: {
    viewModeEnums() {
      return viewModeEnums
    },
    quotes() {
      return [this.value]
    },
    areAllChecked() {
      return this.getPurchaseOrderLines(this.purchaseOrder)?.length === this?.selectedRows?.length
    },
    partsControlEnums() {
      return PartsControlActionTypes
    },
    isReceiveItemsClickable() {
      if (this.selectedRows.length === 0) return false
      const selectedItems = this.getPurchaseOrderLines(this.purchaseOrder).filter((item) => this.selectedRows.includes(item.purchaseOrderItemId))
      const isclickable = selectedItems.every((item) => item.quantity > item.receivedQty)
      return isclickable
    },
    isReturnItemsClickable() {
      if (this.selectedRows.length === 0) return false
      const selectedItems = this.getPurchaseOrderLines(this.purchaseOrder).filter((item) => this.selectedRows.includes(item.purchaseOrderItemId))
      const isclickable = selectedItems.every((item) => item.receivedQty > 0 && item.receivedQty > item.returnQty)
      return isclickable
    },
    isCancelItemsClickable() {
      if (this.selectedRows.length === 0) return false
      const selectedItems = this.getPurchaseOrderLines(this.purchaseOrder).filter((item) => this.selectedRows.includes(item.purchaseOrderItemId))
      const isclickable = selectedItems.every((item) => item.receivedQty == 0)
      return isclickable
    },
    isPartsCheckPurchaseOrder() {
      return this.purchaseOrder.partsCheckPurchaseOrderNo !== ''
    },
    purchaseOrderItems() {
      return this.getPurchaseOrderLines(this.purchaseOrder)
    },
    isDisabled() {
      return this.purchaseOrderItems.every((item) => item.deleted)
    }
  },
  created() {
    this.getReturnParts()
  },
  methods: {
    print1(creditNo) {
      this.$emit('printReturnOrder', creditNo, this.purchaseOrder.vendorId)
    },

    async getReturnParts() {
      const rParts = await ReturnPartsService.getEntitySummaries(this.filter)
      this.returnOrders = rParts
    },
    goToPurchaseOrderManagement(po) {
      sessionStorage.setItem(`${this.$userInfo.sessionId}|purchaseorder|returnUrl`, this.$router.currentRoute.fullPath)
      this.$router.push({
        name: 'PurchaseOrderDetail',
        params: { purchaseOrderId: po.purchaseOrderId }
      })
    },
    itemIsDeleted(quoteItemId) {
      const items = [
        ...this.value.parts.map((i) => {
          return { quoteItemId: i.quoteItemId, isDeleted: i.isDeleted }
        }),
        ...this.value.sublets.map((i) => {
          return { quoteItemId: i.quoteItemId, isDeleted: i.isDeleted }
        }),
        ...this.value.miscs.map((i) => {
          return { quoteItemId: i.quoteItemId, isDeleted: i.isDeleted }
        })
      ]
      return items.find((i) => i.quoteItemId == quoteItemId && i.isDeleted)
    },
    getItemStatus(item) {
      let statusObj = {
        class: '',
        title: '',
        colour: ''
      }
      //// fully received
      if (item.receivedQty === item.quantity && !item.returnQty) {
        statusObj = QuoteItemsOrderStatusTypes.FullyReceived
        //// fully returned
      } else if (item.returnQty >= item.quantity) {
        statusObj = QuoteItemsOrderStatusTypes.AllItemsReturned
        //// partially received
      } else if (item.receivedQty > 0 && !item.returnQty) {
        statusObj = QuoteItemsOrderStatusTypes.PartiallyReceived
        //// partially returned
      } else if (item.returnQty > 0) {
        statusObj = QuoteItemsOrderStatusTypes.PartiallyReturned
        //// calceled or credited
      } else if (item.deleted) {
        statusObj = QuoteItemsOrderStatusTypes.Cancelled
      } else {
        //// part ordered and no changes made since
        statusObj = QuoteItemsOrderStatusTypes.ItemOrdered
      }
      return { classStatus: statusObj.class, title: statusObj.title, colour: statusObj.colour }
    },
    handleSelectItem(item) {
      if (this.isPartsCheckPurchaseOrder) {
        // this.$toast.open({
        //   message: 'Please click the link above to manage PartsCheck Purchase Orders!',
        //   type: 'is-danger',
        //   position: 'is-top',
        //   duration: 5000
        // })
        this.$notification.openNotificationWithType('danger','','Please click the link above to manage PartsCheck Purchase Orders!',5000)

        return
      }
      if (this.selectedRows.includes(item.purchaseOrderItemId)) {
        this.selectedRows = this.selectedRows.filter((row) => row !== item.purchaseOrderItemId)
      } else this.selectedRows.push(item.purchaseOrderItemId)
    },
    addTotal(arr, key) {
      return arr.reduce((a, b) => a + (b[key] * b.quantity || 0), 0)
    },
    getPurchaseOrderLines(purchaseOrder) {
      return [...purchaseOrder.parts, ...purchaseOrder.sublets]
    },
    titleCheckboxChanged() {
      if (this.areAllChecked) this.selectedRows = []
      else {
        this.selectedRows = this.getPurchaseOrderLines(this.purchaseOrder)
          .filter((item) => !item.deleted)
          .map((item) => item.purchaseOrderItemId)
      }
    },
    changeItemStatus(array, status, e) {
      const selectedItems = this.getPurchaseOrderLines(this.purchaseOrder).filter((item) => this.selectedRows.includes(item.purchaseOrderItemId))
      this.$emit('changeItemStatus', status, this.purchaseOrder, selectedItems, this.id)
    },
    async goToPartsCheck(po, e) {
      e?.stopPropagation()
      // get the number after # in the partsCheckPurchaseOrderNo
      const refNo = po.partsCheckPurchaseOrderNo.includes('#') ? po.partsCheckPurchaseOrderNo.split('#')[1] : '0'
      const quoteNo = this.value.prefix + this.value.quoteNo + this.value.suffix
      const result = await PartsCheckService.goToPartsCheck(quoteNo, this.value.subQuoteNo, refNo, 'po')
      if (result) window.open(result, '_blank')
    },
    getTotalReceivedQty(receipts) {
      return receipts.reduce((acc, receipt) => acc + receipt.received, 0)
    },
    getTotalReturnedQty(receipts) {
      return receipts.reduce((acc, receipt) => acc + receipt.returnedQty, 0)
    },
    handleEditOrder(item) {
      this.$emit('handleEditOrder', item, this.purchaseOrder)
    },
    handleReturnOrder(item) {
      this.$emit('handleReturnOrder', item, this.purchaseOrder)
    },
    async print() {
      const params = {
        PONO: this.purchaseOrder.prefix + this.purchaseOrder.purchaseOrderNo + this.purchaseOrder.suffix,
        COMPANYNAME: encodeURIComponent(this.$userInfo.companyName),
        REPORTTITLE: 'PURCHASE ORDER',
        COMPANYID: this.$userInfo.companyId,
        DISPLAYPART: true, //false
        DISPLAYPRICE: true, //false
        VendorID: this.purchaseOrder.vendorId
      }
      // Create KeyValuePairs and add to vuex for later retrieval by PrintPreviewEx component
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      const reportName = `${this.purchaseOrder.prefix}${this.purchaseOrder.purchaseOrderNo}${this.purchaseOrder.suffix}`
      const assetEmail = await PartsControlService.getAssetEmail(this.purchaseOrder.vendorId)

      const emailer = new Emailer()
      if (assetEmail) {
        emailer.toEmails.push(assetEmail)
      }
      emailer.assetId = this.value.purchaseOrderId
      emailer.remark = `Purchase Order ${this.value.prefix}${this.value.purchaseOrderNo}${this.value.suffix}`
      emailer.remarkTypeId = RemarkTypes.Remark
      emailer.subject = `Purchase Order ${this.value.prefix}${this.value.purchaseOrderNo}${this.value.suffix}`
      emailer.reportName = reportName.replace(/[/]/g, '_')
      this.addEmailer(emailer)
      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: 'AT_PurchaseOrder' },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../../assets/style/themes/default/_variables.scss';

.parts-order-grid {
  display: grid;
  // headings:   index, chk, des, pNo, mark, auth, sup, qty, ord, rec, ret, unit price, qte price, status
  grid-template-columns: 3% 5% 30% 10% 10% 3% 3% 3% 3% 10% 10% 10%;
}
</style>

<style >
.tippy-box {
  min-width: unset !important;
}
</style>
