<template>
  <div>
    <!----------------------PARTS ----------------------->
    <div class="mb-4"
      v-if="selectedTab.type == ItemCategoryTypes.PART || selectedViewType != viewTypes.tabbedView">
      <quote-builder-part-headings-component :count="partLength"
        :total-amount="partsTotal"
        :is-full-width="isFullWidth" />
      <div class="rows">
        <draggable @change="itemMoved($event, ItemCategoryTypes.PART)"
          v-model="innerValue.parts"
          @over.prevent
          @enter.prevent
          :clone="$event => cloneHandler($event, ItemCategoryTypes.PART)"
          :group="{ name: ItemCategoryTypes.PART, pull: 'clone', put: true }"
          v-bind="{'disabled': !isRowDraggable, ghostClass: 'draggable-ghost', dragClass: 'draggable-drag'}"
          handle=".item-drag-handle">
          <quote-builder-part-component-item v-for="item in innerValue.parts"
            :key="item.quoteItemId"
            :inner-value="innerValue"
            :is-full-width="isFullWidth"
            :items-assessments="itemsAssessments"
            :item="item"
            :read-only-view="readOnlyView"
            :is-selected="editItemObj.id === item.quoteItemId"
            :vehicle="vehicle"
            :labour-type="innerValue.labourType"
            :sub-quote-no="innerValue.subQuoteNo"
            :quoting-method="innerValue.quotingMethod"
            :show-assessment="innerValue.quoteAssessments.length > 0"
            :next-supplemetry-no="nextSupplemetryNo"
            :orm-object="ormObject"
            @deleteItem="deleteItem"
            @goToPartsCheck="goToPartsCheck"
            @focusNextRow="$emit('focusNextRow', item)"
            @handlePresentAs="itemId => $emit('handlePresentAs', itemId)"
            @itemRowClicked="(value, event, close) => $emit('handleItemRowClick',value, event, close)" />
        </draggable>
      </div>
      <div>
        <button class="button has-text-primary"
          @click="addBlankItem()"
          :disabled="value.readOnly">
          + Add Item
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import QuoteBuilderPartHeadingsComponent from './QuoteBuilderPartHeadingsComponent.vue'
import QuoteBuilderPartComponentItem from './QuoteBuilderPartItem.vue'
import draggable from 'vuedraggable'
import { QuoteItemModel } from '@/classes/viewmodels'
import { ItemCategoryTypes, PartTypes, QuoteItemRevTypes, QuoteItemStatusTypes } from '@/enums'
import cloneDeep from 'lodash.clonedeep'
import QuoteRoutes from '../../../route-types'
import { PartsCheckService } from '../../../services'
import Guid from '@/components/Guid'
import _debounce from 'lodash.debounce'
import { QuoteTotalsMethodMixin, QuoteAssessmentMixin, QuoteItemValidationMixin } from '../../mixins'
import deepDiff from 'deep-diff'

export default {
  name: 'QuoteBuilderPartComponent',
  components: {
    QuoteBuilderPartHeadingsComponent,
    QuoteBuilderPartComponentItem,
    draggable
  },
  mixins: [QuoteTotalsMethodMixin, QuoteAssessmentMixin, QuoteItemValidationMixin],
  props: {
    value: {
      type: Object,
      required: true
    },
    isFullWidth: {
      type: Boolean,
      required: true
    },
    editItemObj: {
      type: Object
    },
    selectedTab: {
      type: Object
    },
    selectedViewType: {
      type: String
    },
    isAudanet: {
      type: Boolean,
      default: false
    },
    viewTypes: {
      type: Object
    },
    vehicle: {
      type: Object
    },
    nextLineNumber: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      innerValue: null
    }
  },
  computed: {
    ormObject() {
      return {
        orm: this.innerValue.orm,
        ormHistory: this.innerValue.parts
      }
    },

    ItemCategoryTypes() {
      return ItemCategoryTypes
    },
    partsTotal() {
      return this.calculateItemCategoryTotal(this.innerValue.quotingMethod, cloneDeep(this.innerValue.parts), ItemCategoryTypes.PART)
    },
    isRowDraggable() {
      return !this.innerValue.readOnly
    },
    itemsAssessments() {
      return this.getItemsAssessments(this.innerValue.parts, this.innerValue.quoteAssessments)
    },
    partLength() {
      // sum .itemQuantity for all parts
      return this.innerValue.parts.filter((p) => !p.isDeleted)?.length || 0
    },
    readOnlyView() {
      return this.innerValue.readOnly
    },
    nextSupplemetryNo() {
      const nextSuppNo = Math.max(...this.innerValue.quoteAssessments.map((i) => i.subQuoteNo)) + 1
      return nextSuppNo
    }
  },
  watch: {
    innerValue: {
      handler: _debounce(function (newVal) {
        if (newVal) {
          // compare the new value with value
          if (deepDiff(newVal, this.value)) {
            this.$emit('handleUpdate', cloneDeep(newVal))
          }
        }
      }, 600),
      deep: true
    }
  },
  mounted() {
    this.$v.entity.parts.$touch()
    // event hub to update the parts
    this.$eventHub.$on('updateParts', this.updateParts)

    this.$eventHub.$on('addBlankItemPart', this.addBlankItem)
    this.$eventHub.$on('deletePart', this.deleteItem)
  },
  beforeDestroy() {
    this.$eventHub.$off('updateParts', this.updateParts)

    this.$eventHub.$off('addBlankItemPart', this.addBlankItem)
    this.$eventHub.$off('deletePart', this.deleteItem)
  },
  created() {
    // compare two objects to see if they are the same, sometimes the parent
    // value might be updated by innervalue and we don't want to update the inner value again
    if (deepDiff(this.innerValue, this.value)) {
      this.innerValue = cloneDeep(this.value)
    }
  },
  methods: {
    updateParts() {
      this.innerValue = cloneDeep(this.value)
    },
    cloneHandler(item, type) {
      return item
    },
    itemMoved(event, type) {
      // if an item was dragged from one list to another, i.e from RR to RWA, Parts to RR etc.
      if (event?.added) {
        const item = event.added.element
        this.handleItemAdded(item, type, event.added.newIndex)
      }

      let sortNo = Math.min(...this.innerValue.parts.map((i) => i.sortNo))
      this.innerValue.parts.forEach((item) => {
        item.sortNo = sortNo
        sortNo++
      })
    },

    handleItemAdded(item, type, index) {
      let newItem = new QuoteItemModel(this.innerValue.quoteId, '', item.itemDesc, ItemCategoryTypes.PART)
      newItem.lineNumber = !this.isAudanet ? this.nextLineNumber : 0
      newItem.itemNo = this.$filters.pad(newItem.lineNumber, 4)
      newItem.itemQuantity = 1
      newItem.value = 0
      newItem.mark = PartTypes.NEW.value
      newItem.markupPercent = 0
      newItem.markupValue = 0
      newItem.buyPrice = 0
      newItem.partNo = ''
      newItem.side = ''
      // replace the item with the new item
      this.innerValue.parts.splice(index, 1, newItem)

      this.$emit('onItemCopied', newItem)
    },
    addBlankItem() {
      let newItem = new QuoteItemModel(this.innerValue.quoteId, '', '', ItemCategoryTypes.PART)
      newItem.lineNumber = !this.isAudanet ? this.nextLineNumber : 0
      newItem.itemNo = this.$filters.pad(newItem.lineNumber, 4)
      newItem.itemQuantity = 1
      newItem.value = 0
      newItem.mark = PartTypes.NEW.value
      newItem.markupPercent = 0
      newItem.markupValue = 0
      newItem.buyPrice = 0
      newItem.partNo = ''
      newItem.side = ''
      newItem.sortNo = this.innerValue.parts.length ? Math.max(...this.innerValue.parts.map((i) => i.sortNo)) + 1 : 1
      if (this.isAudanet) {
        newItem.rev = QuoteItemRevTypes.NonAudaNetPart
      }
      this.innerValue.parts.splice(this.innerValue.parts.length, 1, newItem)
      this.innerValue.lines = this.nextLineNumber + 1
      this.$notification.success('', 'Item added')

      // this.$toast.open({
      //   message: 'Item added',
      //   type: 'is-success',
      //   position: 'is-bottom',
      //   queue: false
      // })

      this.$nextTick(() => {
        this.$emit('newItemAdded', newItem.quoteItemId)
      })
    },
    deleteLinkedItem(item) {
      if (this.isNtar && Guid.validGuid(item.linkedItemId)) {
        const linkedItem = this.innerValue.labours.find((i) => i.quoteItemId === item.linkedItemId && i.isLoadItem && !i.deleted)
        console.log(linkedItem)
        if (linkedItem) {
          this.deletePaintLoadingItem(linkedItem)
        }
      }
    },
    deleteItem(item) {
      let originalItemStatus = item.itemStatus
      this.deleteLinkedItem(item)
      if (item.isNew) {
        const itemIndex = this.innerValue.parts
          .map(function (obj) {
            return obj.quoteItemId
          })
          .indexOf(item.quoteItemId)
        if (itemIndex >= 0) {
          this.innerValue.parts.splice(itemIndex, 1)
          // this.items.splice(index, 1)
        }
      } else {
        item.deleted = true
        item.isDeleted = true
        item.itemStatus = QuoteItemStatusTypes.Deleted
        item.originalItemStatus = originalItemStatus
      }
      this.updateTotal()
      this.$toast.open({
        message: `Item <span class="is-italic">${item.itemDesc}</span> deleted`,
        type: 'is-danger',
        position: 'is-top',
        actionText: 'Undo',
        onAction: () => {
          if (item.isNew) {
            this.innerValue.parts.splice(this.innerValue.parts.length, 1, item)
          } else {
            item.deleted = false
            item.isDeleted = false
            item.itemStatus = originalItemStatus
          }
          this.updateTotal()
        }
      })
    },

    async goToPartsCheck(e, partsCheckReferenceNo, searchType, part) {
      const isManualPartOrder = !part.partStatus && (part.quantityOrdered !== 0 || part.quantityReceived !== 0)
      if (isManualPartOrder) {
        this.$nextTick(() => {
          this.$router.push({
            name: QuoteRoutes.QuotePartsControl.name
          })
        })
        return
      }
      // Not priced Yet
      if (!part.partStatus) {
        this.$notification.warning('', 'Please request the part before proceeding to PartsCheck.')

        // this.$toast.open({
        //   message: 'Please request the part before proceeding to PartsCheck.',
        //   type: 'is-warning',
        //   position: 'is-bottom',
        //   queue: false
        // })
        return
      } else if (
        ///// requested and/or priced // pending order
        (part.partStatus === 'Exp' && part.quantityOrdered == 0 && part.quantityReceived == 0) ||
        (part.partStatus === 'Imp' && part.quantityOrdered == 0 && part.quantityReceived == 0) ||
        (part.partStatus === 'Pord' && part.quantityOrdered == 0 && part.quantityReceived == 0)
      ) {
        '/' + this.innerValue.subQuoteNo
        const quoteNo = this.innerValue.prefix + this.innerValue.quoteNo + this.innerValue.suffix
        const result = await PartsCheckService.goToPartsCheck(quoteNo, this.innerValue.subQuoteNo, partsCheckReferenceNo, searchType)
        if (result) window.open(result, '_blank')
      } else if (
        ///// ordered, received, returned, etc.
        (part.quantityOrdered > 0 && part.quantityReceived == 0 && part.quantityReturned == 0) ||
        (part.quantityOrdered > 0 && part.quantityReceived > 0 && part.quantityOrdered == part.quantityReceived) ||
        (part.quantityOrdered > 0 && part.quantityReceived > 0 && part.quantityOrdered !== part.quantityReceived) ||
        (part.quantityOrdered > 0 && part.quantityReturned > 0 && part.quantityOrdered == part.quantityReturned) ||
        (part.quantityOrdered > 0 && part.quantityReturned > 0 && part.quantityOrdered !== part.quantityReturned)
      ) {
        const quoteNo = this.innerValue.prefix + this.innerValue.quoteNo + this.innerValue.suffix
        const result = await PartsCheckService.goToPartsCheck(quoteNo, this.innerValue.subQuoteNo, partsCheckReferenceNo, 'po')
        if (result) window.open(result, '_blank')
      }
    },
    updateTotal() {}
  }
}
</script>

<style scoped>
/* Your component's styles go here */
</style>