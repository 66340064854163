<template>
  <div>
    <!----------------------Sublets ----------------------->
    <div class="mb-4"
      v-if="selectedTab.type == ItemCategoryTypes.SUBL || selectedViewType != viewTypes.tabbedView">
      <quote-builder-sublet-headings-component :count="subletLength"
        :total-amount="subletsTotal"
        :is-full-width="isFullWidth" />
      <div class="rows">
        <draggable @change="itemMoved($event, ItemCategoryTypes.SUBL)"
          v-model="innerValue.sublets"
          @over.prevent
          @enter.prevent
          v-bind="{'disabled': !isRowDraggable, chosen: 'chosen', ghostClass: 'draggable-ghost', dragClass: 'draggable-drag'}"
          :clone="$event => cloneHandler($event, ItemCategoryTypes.SUBL)"
          :group="{ name: ItemCategoryTypes.SUBL, pull: 'clone', put: true }"
          handle=".item-drag-handle">
          <quote-builder-sublet-item v-for="item in subletItems"
            :key="item.id"
            :inner-value="innerValue"
            :is-full-width="isFullWidth"
            :items-assessments="itemsAssessments"
            :item="item"
            :edit-item-id="editItemObj.id"
            :vehicle="vehicle"
            :sublets="sublets"
            :vendors="vendors"
            @deleteItem="item => deleteItem(item)"
            @focusNextRow="$emit('focusNextRow', item)"
            @handlePresentAs="itemId => $emit('handlePresentAs', itemId)"
            @itemRowClicked="(value, event, close) => $emit('handleItemRowClick',value, event, close)" />
        </draggable>
      </div>
      <div>
        <button class="button has-text-primary"
          @click="addBlankItem()"
          :disabled="value.readOnly">
          + Add Item
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import QuoteBuilderSubletHeadingsComponent from './QuoteBuilderSubletHeadingsComponent.vue'
import QuoteBuilderSubletItem from './QuoteBuilderSubletItem.vue'
import draggable from 'vuedraggable'
import { ItemCategoryTypes, QuoteItemStatusTypes } from '@/enums'
import cloneDeep from 'lodash.clonedeep'
import { QuoteTotalsMethodMixin, QuoteAssessmentMixin, QuoteItemValidationMixin } from '../../mixins'
import QuoteService from '../../.././QuoteService'
import VendorService from '@/views/vendor/VendorService'
import Guid from '../../../../../components/Guid'
import { QuoteItemModel } from '@/classes/viewmodels'
import deepDiff from 'deep-diff'
import _debounce from 'lodash.debounce'

export default {
  name: 'QuoteBuilderSubletComponent',
  components: {
    QuoteBuilderSubletHeadingsComponent,
    QuoteBuilderSubletItem,
    draggable
  },
  mixins: [QuoteTotalsMethodMixin, QuoteAssessmentMixin, QuoteItemValidationMixin],
  props: {
    value: {
      type: Object,
      required: true
    },
    isFullWidth: {
      type: Boolean,
      required: true
    },
    editItemObj: {
      type: Object
    },
    selectedTab: {
      type: Object
    },
    selectedViewType: {
      type: String
    },
    isAudanet: {
      type: Boolean,
      default: false
    },
    viewTypes: {
      type: Object
    },
    vehicle: {
      type: Object
    },
    nextLineNumber: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      innerValue: null,
      sublets: [],
      vendors: []
    }
  },
  computed: {
    ItemCategoryTypes() {
      return ItemCategoryTypes
    },
    subletItems() {
      return this.innerValue.sublets
    },
    subletLength() {
      return this.innerValue.sublets.filter((i) => !i.isDeleted).length
    },
    subletsTotal() {
      return this.calculateItemCategoryTotal(this.innerValue.quotingMethod, cloneDeep(this.innerValue.sublets), ItemCategoryTypes.SUBL)
    },
    isRowDraggable() {
      return !this.innerValue.readOnly
    },
    itemsAssessments() {
      return this.getItemsAssessments(cloneDeep(this.innerValue.sublets), cloneDeep(this.innerValue.quoteAssessments))
    }
  },
  watch: {
    innerValue: {
      handler: _debounce(function (newVal) {
        if (newVal) {
          // compare the new value with value
          if (deepDiff(this.innerValue, this.value)) {
            this.$emit('handleUpdate', cloneDeep(newVal))
          }
        }
      }, 600),
      deep: true
    }
  },
  mounted() {
    this.$v.entity.sublets.$touch()
    this.$eventHub.$on('updateSublets', this.updateSublets)
    this.$eventHub.$on('addBlankItemSublet', this.addBlankItem)
    this.$eventHub.$on('deleteSubletItem', this.deleteItem)
  },
  beforeDestroy() {
    this.$eventHub.$off('updateSublets', this.updateSublets)
    this.$eventHub.$off('addBlankItemSublet', this.addBlankItem)
    this.$eventHub.$off('deleteSubletItem', this.deleteItem)
  },
  created() {
    this.innerValue = cloneDeep(this.value)
    this.getSublets()
    this.getVendors()
  },
  methods: {
    updateSublets() {
      // compare two objects to see if they are the same, sometimes the parent
      // value might be updated by innervalue and we don't want to update the inner value again
      if (deepDiff(this.innerValue, this.value)) {
        this.innerValue = cloneDeep(this.value)
      }
    },
    cloneHandler(item, type) {
      // change the quoteItemId to a new guid so we can find it later and change to correct type on drop
      // TODO: looks like this doesn't work, leave it as is
      // item.quoteItemId = Guid.newGuid()
      return item
    },
    itemMoved(event, type) {
      // if an item was dragged from one list to another, i.e from RR to RWA, Parts to RR etc.
      if (event?.added) {
        const item = event.added.element
        this.handleItemAdded(item, type, event.added.newIndex)
      }
      // console.log(event.moved)
      let sortNo = Math.min(...this.innerValue.sublets.map((i) => i.sortNo))
      this.innerValue.sublets.forEach((item) => {
        item.sortNo = sortNo
        sortNo++
      })
    },
    handleItemAdded(item, type, index) {
      let newItem = new QuoteItemModel(this.innerValue.quoteId, '', item.itemDesc, ItemCategoryTypes.SUBL)
      newItem.lineNumber = this.innerValue.audatex === null ? this.nextLineNumber : 0
      newItem.itemNo = this.$filters.pad(newItem.lineNumber, 4)
      newItem.value = 0
      newItem.sortNo = this.innerValue.sublets.length ? Math.max(...this.innerValue.sublets.map((i) => i.sortNo)) + 1 : 1
      this.innerValue.lines = this.nextLineNumber + 1
      // replace the item with the new item
      this.innerValue.sublets.splice(index, 1, newItem)

      this.$emit('onItemCopied', newItem)
    },
    async getSublets() {
      this.sublets = await QuoteService.getSubletsProto(!this.innerValue.audatex)
    },
    async getVendors() {
      const filter = {
        id: Guid.empty(),
        name: '',
        pageIndex: 1,
        pageSize: 50
      }

      const vendors = await VendorService.getVendorDropdownProto2(filter)

      this.vendors = vendors
    },
    addBlankItem() {
      let newItem = new QuoteItemModel(this.innerValue.quoteId, '', '', ItemCategoryTypes.SUBL)
      newItem.lineNumber = this.innerValue.audatex === null ? this.nextLineNumber : 0
      newItem.itemNo = this.$filters.pad(newItem.lineNumber, 4)
      newItem.value = 0
      newItem.sortNo = this.innerValue.sublets.length ? Math.max(...this.innerValue.sublets.map((i) => i.sortNo)) + 1 : 1
      this.innerValue.sublets.splice(this.innerValue.sublets.length, 1, newItem)
      this.innerValue.lines = this.nextLineNumber + 1
      this.$notification.success('', 'Item added')

      // this.$toast.open({
      //   message: 'Item added',
      //   type: 'is-success',
      //   position: 'is-bottom',
      //   queue: false
      // })

      this.$nextTick(() => {
        this.$emit('newItemAdded', newItem.quoteItemId)
      })
    },
    deleteItem(item) {
      let originalItemStatus = item.itemStatus
      if (item.isNew) {
        const itemIndex = this.innerValue.sublets
          .map(function (obj) {
            return obj.quoteItemId
          })
          .indexOf(item.quoteItemId)
        if (itemIndex >= 0) {
          this.innerValue.sublets.splice(itemIndex, 1)
          // this.items.splice(index, 1)
        }
      } else {
        item.deleted = true
        item.isDeleted = true
        item.itemStatus = QuoteItemStatusTypes.Deleted
        item.originalItemStatus = originalItemStatus
      }
      // this.updateTotal()
      this.$toast.open({
        message: `Item <span class="is-italic">${item.itemDesc}</span> deleted`,
        type: 'is-danger',
        position: 'is-top',
        actionText: 'Undo',
        onAction: () => {
          if (item.isNew) {
            this.innerValue.sublets.splice(this.innerValue.sublets.length, 1, item)
            // this.items.splice(this.items.length, 1, item)
          } else {
            item.deleted = false
            item.isDeleted = false
            item.itemStatus = originalItemStatus
          }
          // this.updateTotal()
        }
      })
    }
  }
}
</script>

<style scoped>
/* Your component's styles go here */
</style>