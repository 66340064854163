import { ItemCategoryTypes, PartTypes, LabourTimeTypes } from '@/enums'
import QuoteTotalsMethodMixin from './QuoteTotalsMethodMixin'
import QuoteNotificationMixin from './QuoteNotificationMixin'
import QuoteItemsMixin from './QuoteItemsMixin'
import { mapGetters, mapActions } from 'vuex'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'

const moduleName = 'quotes'

export default {
  mixins: [
    QuoteTotalsMethodMixin,
    QuoteItemsMixin,
    QuoteNotificationMixin
  ],
  computed: {
    partTotal() {
      if (this.mixinEntity) {
        return this.calculateItemCategoryTotal(this.mixinEntity.quotingMethod, this.mixinEntity.parts, ItemCategoryTypes.PART)
      }
    },
    ...mapGetters('quotes', {
      snapshotDiff: 'snapshotDiff',
      quoteInsurer: 'quoteInsurer',
      ntarLoadingItems: 'ntarLoadingItems',
      ntarOtherLoadingItems: 'ntarOtherLoadingItems',
      ntarLoadingValues: 'ntarLoadingValues',
      partsCheckSettingExists: 'partsCheckSettingExists',
      partsCheckSetting: 'partsCheckSetting'
    }),
    isNtar() {
      return (
        this.innerValue.labourType === LabourTimeTypes.NTAR ||
        this.innerValue.labourType === LabourTimeTypes.LTAR ||
        this.innerValue.labourType === LabourTimeTypes.eMTA
      )
    }
  },
  methods: {
    ...mapActions(moduleName, ['getExternalSetting']),
    updateValue(index, item) {
      // if (item && item.deleteOtherItem && item.itemQuantity > 0) {
      //   item.itemQuantity = item.itemQuantity * -1
      // }
      this.updateTotal()
    },
    updateMarkup(index, item) {
      if (this.items && !item.deleteOtherItem) {
        const itemToUpdate = this.innerValue.parts.find((i) => i.quoteItemId === item.quoteItemId)
        const markup = this.getMarkupPercent(item)
        itemToUpdate.markupValue = roundAwayFromZero(item.value + item.value * (markup / 100))
      }
      this.updateTotal()
    },
    onPartConditionChange(item) {
      if (this.items) {
        const itemToUpdate = this.innerValue.parts.find((i) => i.quoteItemId === item.quoteItemId)
        const markup = this.getMarkupPercent(item)
        itemToUpdate.markupValue = roundAwayFromZero(item.value + item.value * (markup / 100))
        this.addUpdatePartPaintLoading(item)
      }
      this.updateTotal()
    },
    getMarkupPercent(item) {
      let markup = 0
      switch (item.mark) {
        case PartTypes.NEW.value:
          markup = this.innerValue.newMarkup
          break
        case PartTypes.USED.value:
          markup = this.innerValue.usedMarkup
          break
        case PartTypes.EXCH.value:
          markup = this.innerValue.exchangeMarkup
          break
        case PartTypes.RECR.value:
          markup = this.innerValue.recoreMarkup
          break
        case PartTypes.RECD.value:
          markup = this.innerValue.reconditionedMarkup
          break
        case PartTypes.ALTN.value:
          markup = this.innerValue.usedMarkup
          break
        case PartTypes.AFT.value:
          markup = this.innerValue.afterMarketMarkup
          break
        case PartTypes.CAFT.value:
          markup = this.innerValue.afterMarketMarkup
          break
        case PartTypes.AFTA.value:
          markup = this.innerValue.afterMarketMarkup
          break
      }
      return markup
    },
    async addUpdatePartPaintLoading(partItem) {
      if (!this.isNtar || !this.ntarLoadingItems || !this.quoteInsurer) {
        // console.log('!this.isNtar')
        return
      }
      const linkedItem = this.innerValue.labours.find((i) => i.linkedItemId === partItem.quoteItemId && i.isLoadItem && !i.deleted)
      let ntarLoadingItem = this.ntarLoadingItems.find((n) => n.itemNo === partItem.itemNo && n.category === 'PRT')
      if (!ntarLoadingItem && partItem.side) {
        ntarLoadingItem = this.ntarLoadingItems.find((n) => n.itemNo === `${partItem.itemNo}${partItem.side}` && n.category === 'PRT')
      }
      const hasPaintLoading = ntarLoadingItem && (partItem.mark === PartTypes.USED.value || partItem.mark === PartTypes.EXCH.value)
      if (!hasPaintLoading && !linkedItem) {
        // console.log('!hasPaintLoading', linkedItem)
        return
      } else if (linkedItem && partItem.mark !== PartTypes.USED.value && partItem.mark !== PartTypes.EXCH.value) {
        // Delete paint loading item
        // console.log('Deleting paint loading')
        this.deletePaintLoadingItem(linkedItem)
        return
      }

      let paintItem = this.innerValue.labours.find((i) => i.itemType === ItemCategoryTypes.PAINT && i.itemNo === ntarLoadingItem.raItemNo && !i.deleted)
      let paintHour = 0
      if (!paintItem) {
        const paintItems = await this.getDefaultPaintItem(ntarLoadingItem.raItemNo)
        paintItem = paintItems[0]
        paintHour = paintItem.paintHour
      } else {
        paintHour = paintItem.hourValue
      }
      // console.log(paintItem)
      const paintLoadingItemNo = (parseInt(ntarLoadingItem.raItemNo, 10) + 5000).toString()
      let paintLoadingItem =
        linkedItem ||
        this.innerValue.labours.find((p) => p.itemType === ItemCategoryTypes.PAINT && p.itemNo === paintLoadingItemNo && p.isLoadItem && !p.deleted)
      let isNew = false
      let loadingValueType = ''
      if (partItem.mark === PartTypes.USED.value) {
        loadingValueType = 3
      } else if (partItem.mark === PartTypes.EXCH.value) {
        loadingValueType = 2
      }
      const loadingValue = this.quoteInsurer.insurerLoadingValues.find((v) => v.category === 'PRT' && v.loadingValueType === loadingValueType)
      const loadingPercent = (!loadingValue ? 0 : loadingValue.value) * 100
      partItem.loadingPercent = loadingPercent
      if (!paintLoadingItem) {
        paintLoadingItem = new QuoteItemModel(this.value.quoteId, paintLoadingItemNo, `Paint Loading, ${partItem.itemDesc}`, ItemCategoryTypes.PAINT)
        paintLoadingItem.sortNo = this.value.length ? Math.max(...this.value.map((i) => i.sortNo)) + 1 : 1
        isNew = true
      }
      // console.log(paintHour, partItem.loadingPercent)
      paintLoadingItem.lineNumber = this.nextLineNumber
      paintLoadingItem.isLoadItem = true
      paintLoadingItem.linkedItemId = partItem.quoteItemId
      paintLoadingItem.hourValue = roundAwayFromZero((paintHour * partItem.loadingPercent) / 100)
      paintLoadingItem.rate = this.value.shopRate
      paintLoadingItem.dollarValue = roundAwayFromZero(paintLoadingItem.hourValue * this.value.shopRate)
      partItem.linkedItemId = paintLoadingItem.quoteItemId
      if (isNew) {
        this.innerValue.labours.splice(this.innerValue.labours.length, 1, paintLoadingItem)
        this.innerValue.lines = paintLoadingItem.lineNumber + 1
      }
      // console.log('part paint loading added', paintLoadingItem.linkedItemId)
    },
    deletePaintLoadingItem(item) {
      let originalItemStatus = item.itemStatus
      if (item.isNew) {
        const itemIndex = this.innerValue.labours
          .map(function (obj) {
            return obj.quoteItemId
          })
          .indexOf(item.quoteItemId)
        if (itemIndex >= 0) {
          this.innerValue.labours.splice(itemIndex, 1)
        }
      } else {
        item.deleted = true
        item.isDeleted = true
        item.itemStatus = QuoteItemStatusTypes.Deleted
      }
      this.updateTotal()
      this.$toast.open({
        message: `Item <span class="is-italic">${item.itemDesc}</span> deleted`,
        type: 'is-danger',
        position: 'is-top',
        actionText: 'Undo',
        onAction: () => {
          if (item.isNew) {
            this.innerValue.labours.splice(this.innerValue.labours.length, 1, item)
            // this.items.splice(this.items.length, 1, item)
          } else {
            item.deleted = false
            item.isDeleted = false
            item.itemStatus = originalItemStatus
          }
          this.updateTotal()
        }
      })
    },
    async getDefaultPaintItem(itemNo) {
      const filter = {
        countryId: Guid.empty(),
        companyId: this.value.companyId,
        query: itemNo,
        butterflyCode: '',
        scheduleId: this.vehicle.scheduleId || '',
        bodyId: this.vehicle.bodyId || '',
        bodySize: '',
        paintGroup: this.vehicle.paintGroup || '',
        variantId: 0,
        nvic: '',
        sortColumn: '',
        sortOrder: '',
        pageIndex: 1,
        pageSize: 1
      }
      const paintItem = await QuoteService.getAvailableLabourItems(filter, this.value.labourType.toLowerCase())
      return paintItem
    }
  }
}
