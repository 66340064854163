<template>
  <base-modal-ex v-if="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="80"
    :min-height="80"
    has-modal-card>
    <header class="modal-card-head">
      <div class="modal-card-title"
        :class="{ 'is-flex is-align-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <span>
          <span v-if="isPnet">PNET</span>
          <span v-if="isOrm">ORM</span>
          Utilities
        </span>
      </div>
    </header>
    <section class="modal-card-body">
      <div class="tabs">
        <ul>
          <li v-if="isOrm"
            :class="{ 'is-active' : subComponent === 'OrmQuoteItems' }">
            <a @click="subComponent = 'OrmQuoteItems'">Items</a>
          </li>
          <li v-if="isPnet || isOrm"
            :class="{ 'is-active' : subComponent === 'OrmQuoteImages' }">
            <a @click="subComponent = 'OrmQuoteImages'">Images</a>
          </li>
          <li v-if="isOrm"
            :class="{ 'is-active' : subComponent === 'OrmQdeVersionChange' }">
            <a @click="subComponent = 'OrmQdeVersionChange'">QDE Version Change</a>
          </li>
        </ul>
      </div>
      <div v-if="subComponent === 'OrmQuoteItems'">
        <div class="subtitle">Set item status</div>
        <bulma-table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth"
          :columns="itemColumns"
          :total-rows="itemCount"
          :show-pagination="false">
          <tr v-for="item in items"
            :key="item.quoteItemId">
            <td>{{ item.lineNumber }}</td>
            <td>{{ item.itemNo }}</td>
            <td>{{ item.itemDesc }}</td>
            <td class="has-vertical-middle has-text-centered">
              <span class="item-type-tag"
                :class="`tag is-${item.itemType.toLowerCase()}`">{{ item.itemType }}</span>
            </td>
            <td class="has-text-centered">
              <input type="text"
                class="input item-status-sent"
                v-model="item.itemStatus">
            </td>
            <td class="has-text-centered">
              <input type="text"
                class="input item-status-sent"
                v-model="item.itemStatusSent">
            </td>
          </tr>
        </bulma-table>
      </div>
      <div v-if="subComponent === 'OrmQuoteImages'">
        <div class="subtitle">Set or reset image sent status</div>
        <div class="columns is-multiline">
          <div v-for="(image, index) in imagesWithInfo"
            :key="image.quoteImageId"
            class="column is-narrow p-2">
            <div v-if="image.imageContent"
              class="card"
              style="box-shadow: 0 0 0 1px #d9d9d9">
              <header class="card-header">
                <div class="card-header-title p-2 has-background-grey-lighter">
                  <div class="container is-fluid mx-0 px-0">
                    <div class="columns is-gapless">
                      <div class="column">
                        <div class="field">
                          <div class="control">
                            <div class="pretty p-icon p-curve p-smooth mr-2">
                              <input :id="`chk-pretty-image1${index}`"
                                :name="`chk-pretty-image${index}`"
                                v-model="image.isSelected"
                                type="checkbox">
                              <div class="state p-success">
                                <i class="icon mdi mdi-check" />
                                <label />
                              </div>
                            </div>
                            <span v-show="image.isSelected">Sent</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <div class="card-image">
                <a @click="viewImage(index)">
                  <figure class="image is-128x128">
                    <img v-if="image.imageContent"
                      class="image is-128x128"
                      style="object-fit: cover"
                      :src="image.imageContent"
                      :key="image.quoteImageId"
                      :id="image.quoteImageId">
                  </figure>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="subComponent === 'OrmQdeVersionChange'">
        <div class="subtitle">Send QDE Version Change 3.00 to ORM</div>
        <label class="label">Repairer ID</label>
        <div class="field is-grouped">
          <div class="control">
            <input type="text"
              class="input"
              :class="{ 'is-danger': $v.repairerId.$error }"
              style="max-width: 12em"
              placeholder="Repairer ID"
              v-model="repairerId">
          </div>
          <div class="control">
            <button class="button is-primary"
              @click="sendQdeVersionChange">Send</button>
          </div>
        </div>
        <span v-if="$v.repairerId.$error"
          class="help is-danger">Please enter a valid Repairer ID</span>
      </div>
    </section>
    <footer class="modal-card-foot ">
      <div class="container is-fluid px-0">
        <div class="columns">
          <div v-if="subComponent === 'OrmQuoteItems'"
            class="column">
            <div class="is-flex is-justify-content-flex-start">
              <button class="button is-primary"
                @click="rewindItemsStatus">Rewind Status</button>
              <button class="button is-primary"
                @click="setItemsStatus('A')">Set All Added</button>
            </div>
          </div>
          <div v-if="subComponent === 'OrmQuoteImages'"
            class="column">
            <div class="is-flex is-justify-content-flex-start">
              <button class="button is-primary"
                @click="clearImagesSentStatus">Clear All</button>
            </div>
          </div>
          <div class="column is-flex is-justify-content-flex-end">
            <button class="button is-success tooltip"
              data-tooltip="Done"
              @click="ok">
              <span>Ok</span>
            </button>
            <button class="button tooltip"
              data-tooltip="Cancel"
              @click="cancel">
              <span>Cancel</span>
            </button>
          </div>
        </div>
      </div>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import _cloneDeep from 'lodash/cloneDeep'
import { TableColumn } from '@/classes'
import { QuoteImageMetaModel } from '@/classes/viewmodels'
import BulmaTable from '@/components/BulmaTable'
import { QuoteService, OrmService } from '@/services'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'OrmPnetUtilitiesModal',
  components: {
    BaseModalEx,
    BulmaTable
  },
  validations: {
    repairerId: {
      required
    }
  },
  mixins: [],
  props: {
    value: null,
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconName: {
      type: String,
      default: 'mdi-tools'
    },
    iconType: {
      type: String,
      default: 'primary'
    }
  },
  data: () => {
    return {
      size: 200,
      isActive: false,
      innerValue: null,
      subComponent: 'OrmQuoteItems',
      isLoadingImages: false,
      imageIds: null,
      imagesWithInfo: [],
      originalImageMetas: [],
      repairerId: ''
    }
  },
  computed: {
    itemColumns() {
      return [
        new TableColumn('Line No.', 'lineNumber', false, 'asc', 'left'),
        new TableColumn('Item No.', 'lineNumber', false, 'asc', 'left'),
        new TableColumn('Description', 'itemDesc', true, 'asc', 'left'),
        new TableColumn('Type', 'itemType', false, 'asc', 'centered'),
        new TableColumn('Status', 'itemStatus', true, 'asc', 'centered'),
        new TableColumn('Sent Status', 'itemStatusSent', true, 'asc', 'centered')
      ]
    },
    items() {
      return [
        ...this.innerValue.labours,
        ...this.innerValue.others,
        ...this.innerValue.parts,
        ...this.innerValue.opgs,
        ...this.innerValue.miscs,
        ...this.innerValue.sublets
      ]
    },
    itemCount() {
      return (
        this.innerValue.labours.length +
        this.innerValue.parts.length +
        this.innerValue.opgs.length +
        this.innerValue.others.length +
        this.innerValue.miscs.length +
        this.innerValue.sublets.length
      )
    },
    isPnet() {
      return this.innerValue.pnet !== null && this.innerValue.pnet !== undefined
    },
    isOrm() {
      return this.innerValue.orm !== null && this.innerValue.orm !== undefined
    }
    // mapped() {
    //   return this.imagesWithInfo.map(i => {
    //     const {quoteImageId, statusId} = i
    //     return {quoteImageId, statusId}
    //   })
    // }
  },
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  created() {
    this.innerValue = _cloneDeep(this.value)
    this.getThumbnails()
  },
  mounted() {
    this.isActive = true
    if (this.isOrm) {
      this.subComponent = 'OrmQuoteItems'
    } else if (this.isPnet) {
      this.subComponent = 'OrmQuoteImages'
    }
  },
  beforeDestroy() {},
  methods: {
    ok() {
      this.imagesWithInfo.forEach((i) => {
        i.statusId = i.isSelected ? 2 : 1
      })
      let updatedImageMetas = []
      this.imagesWithInfo.forEach((i) => {
        const originalMeta = this.originalImageMetas.find((m) => m.quoteImageId === i.quoteImageId)
        console.log(originalMeta.statusId, i.statusId)
        if (originalMeta && originalMeta.statusId !== i.statusId) {
          const newMeta = QuoteImageMetaModel.createFromQuoteImage(i)
          updatedImageMetas.splice(updatedImageMetas.length, 1, newMeta)
        }
      })
      if (updatedImageMetas && updatedImageMetas.length > 0) {
        QuoteService.updateImageStatuses(updatedImageMetas)
      }
      this.$emit('input', this.innerValue)
      this.isActive = false
      this.$emit('update:active', false)
    },
    cancel() {
      this.isActive = false
      this.$emit('update:active', false)
    },
    rewindItemsStatus() {
      this.items.forEach((i) => (i.itemStatus = i.itemStatusSent))
    },
    setItemsStatus(status) {
      this.items.forEach((i) => (i.itemStatus = status))
    },
    async getThumbnailWithInfo(id, index) {
      const thumbnail = await QuoteService.getImageThumbnailWithInfo(id, this.size)
      return thumbnail
    },
    clearImagesSentStatus() {
      this.imagesWithInfo.forEach((i) => {
        i.statusId = 1
        i.isSelected = false
      })
    },
    async getThumbnails() {
      this.isLoadingImages = true
      this.imagesWithInfo.splice(0, this.imagesWithInfo.length)
      const vm = this
      this.imageIds = await QuoteService.getImageIds(this.value.quoteId)
      const promises = this.imageIds.map(function (id, index) {
        return vm.getThumbnailWithInfo(id, index)
      })
      this.imagesWithInfo = await Promise.all(promises)
      this.imagesWithInfo.forEach((i) => {
        if (i.statusId === 2) {
          i.isSelected = true
        }
      })
      this.imagesWithInfo.forEach((i) => {
        const newMeta = QuoteImageMetaModel.createFromQuoteImage(i)
        this.originalImageMetas.splice(this.originalImageMetas.length, 1, newMeta)
      })
      this.isLoadingImages = false
    },
    async sendQdeVersionChange() {
      this.$v.repairerId.$touch()
      if (this.$v.repairerId.$error) {
        return
      }
      try {
        await OrmService.sendQdeVersionChange(this.repairerId)
        // this.$toast.open({
        //   message: 'QDE Version Change sent successfully.',
        //   type: 'is-success',
        //   duration: 5000
        // })
      this.$notification.openNotificationWithType('success','','QDE Version Change sent successfully.',5000)

      } catch (e) {
        this.isActive = false
        this.$emit('update:active', false)
        // this.$toast.open({
        //   message: `${e.message}`,
        //   type: 'is-danger',
        //   duration: 5000
        // })
      this.$notification.openNotificationWithType('danger','',`${e.message}`,5000)

      }
      //
      // console.log(result)
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
.item-type-tag {
  width: 52px;
}
.item-status {
  max-width: 5em;
}
.item-status-sent {
  max-width: 5em;
}
</style>