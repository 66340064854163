<template>

  <quote-builder-sublet-item-template :is-full-width="isFullWidth"
    :is-heading="true"
    class=" has-text-weight-bold bb-primary-1 pb-2 mb-2 has-background-white quote-item-heading">
    <template v-slot:description>
      <div class="is-flex is-align-items-center has-text-weight-bold ">
        <span>Sublets</span>
        <span class="ml-2">({{ count }})</span>
        <!-- <span class=" ml-5 is-size-6 has-background-primary has-text-white px-2 is-rounded">
          {{ count }}
        </span> -->
      </div>
    </template>
    <template v-slot:sublet_type>
      <div class="is-flex is-justify-content-center">
        <span>Type</span>
      </div>
    </template>

    <template v-slot:report_blend>
      <div class=" is-align-items-center">
        <span>Rpt</span>
      </div>
    </template>

    <template v-slot:sublet_vendor>
      <div class="is-flex is-justify-content-center">
        <span>Vendor</span>
      </div>
    </template>

    <template v-slot:buy_price>
      <div class="is-flex is-justify-content-end is-align-items-center ">
        <span>Buy Price</span>
      </div>
    </template>

    <template v-slot:total_value>
      <div class="is-flex is-justify-content-end is-align-items-center mx-2 ">
        <span>{{ $filters.formatCurrency(totalAmount, $userInfo.locale, 2) }}</span>
      </div>
    </template>
  </quote-builder-sublet-item-template>

</template>

<script>
import QuoteBuilderSubletItemTemplate from '@/views/quote/quoteBuilder/templates/QuoteBuilderSubletItemTemplate.vue'

export default {
  name: 'QuoteBuilderSubletHeadingsComponent',
  components: {
    QuoteBuilderSubletItemTemplate
  },
  props: {
    isFullWidth: {
      type: Boolean,
      default: true
    },
    count: {
      type: Number,
      default: 0
    },
    totalAmount: {
      type: Number,
      default: 0
    }
  }
  // Your component's logic goes here
}
</script>

<style scoped>
/* Your component's styles go here */
</style>