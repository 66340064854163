import Vue from 'vue'
import { BulmaToast } from '@/components/BulmaToast'

export default {
  install() {
    Vue.prototype.$notification = Vue.notification = this
  },

  success(title, message) {
    BulmaToast.open({
      message: message,
      type: 'is-success',
      position: 'is-top',
      queue: false
    })
  },

  error(title, message) {
    BulmaToast.open({
      message: message,
      type: 'is-danger',
      position: 'is-top',
      queue: false
    })
  },

  warning(title, message) {
    BulmaToast.open({
      message: message,
      type: 'is-warning',
      position: 'is-top',
      queue: false
    })
  },

  validationError(title, message) {
    // let errTitle = 'Validation'

    if (title && title !== '') {
      errTitle = title
    }

    let errMessage = 'Validation errors. Please fix before saving'

    if (message && message !== '') {
      errMessage = message
    }
    BulmaToast.open({
      message: errMessage,
      type: 'is-danger',
      position: 'is-top',
      queue: false
    })
  },

  openNotificationWithType(type, title, message, duration) {
    BulmaToast.open({
      message: message,
      type: `is-${type}`,
      position: 'is-top',
      queue: false,
      duration: duration || 3000
    })
  },

  openMessageXhrError(title, xhrRequest) {
    // console.log(xhrRequest.response)
    var xhrResponse
    const status = xhrRequest.response.request.status
    var message
    if (xhrRequest.response.request.response) {
      xhrResponse = JSON.parse(xhrRequest.response.request.response)
      message = xhrResponse.message
      // console.log(xhrResponse.modelState[0])
      if (xhrResponse.modelState) {
        // console.log(xhrResponse.modelState[Object.keys(xhrResponse.modelState)[0]])
        // console.log(Object.values(xhrResponse.modelState)[0][0])
        Object.keys(xhrResponse.modelState).forEach(function(key) {
          // console.log(xhrResponse.modelState[key])
          message += ` ${xhrResponse.modelState[key]}`
        })
        // message += ` ${xhrResponse.modelState[Object.keys(xhrResponse.modelState)[0]]}`
      }
    } else {
      message = xhrRequest.response.request.statusText
    }
    BulmaToast.open({
      message: `Error ${status}: ${message}`,
      type: 'is-danger',
      position: 'is-top',
      queue: false
    })
  }
}
