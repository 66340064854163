<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-vertical">
      <div v-if="showAddNew"
        class="tile is-parent">
        <article class="tile is-child box detail-page-tile">
          <div class="field">
            <div class="field-body">
              <div class="field">
                <div class="label">Purchase Date</div>
                <div class="control">
                  <v-date-picker v-model="selectedPurchaseDate"
                    :masks="formats"
                    :attributes="attrs"
                    :locale="$userInfo.locale">
                    <template v-slot="{ inputValue, inputEvents }">
                      <div class="field">
                        <div class="control has-icons-left">
                          <input type="text"
                            class="input"
                            placeholder="Date To..."
                            :value="inputValue"
                            v-on="inputEvents"
                            readonly>
                          <span class="icon is-small is-left">
                            <i class="mdi mdi-calendar mdi-18px" />
                          </span>
                        </div>
                      </div>
                    </template>
                  </v-date-picker>
                </div>
              </div>
              <div class="field">
                <div class="label">Amount</div>
                <div class="control">
                  <vue-numeric class="input has-text-right"
                    :min="0"
                    :max="1000"
                    :precision="2"
                    v-model.number="amount" />
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="label">Notes</div>
            <div class="control">
              <textarea class="textarea"
                rows="5"
                v-model="notes" />
            </div>
          </div>
          <div class="buttons is-right">
            <button class="button is-primary"
              @click="addNewToList()">
              <span class="icon">
                <i class="mdi mdi-plus" />
              </span>
              <span>Add</span>
            </button>
            <button class="button"
              @click="toggleAddNew()">
              <span class="icon">
                <i class="mdi mdi-window-close" />
              </span>
              <span>Cancel</span>
            </button>
          </div>
        </article>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child box detail-page-tile">
          <div class="field">
            <div class="label">Select Company</div>
            <div class="control is-expanded">
              <multiselect v-if="companyNames"
                v-model="selectedCompany"
                deselect-label="Can't remove this value"
                track-by="companyId"
                label="name"
                placeholder="Select a company"
                :options="companyNames"
                :loading="isCompanyLoading"
                :internal-search="false"
                :options-limit="companyNameFilter.pageSize"
                @search-change="getCompanyNames"
                :searchable="true"
                :show-labels="false"
                :allow-empty="true">
                <template slot="option"
                  slot-scope="props">
                  <span>{{ `${props.option.name}` }}</span>
                </template>
                <span class="has-text-danger"
                  slot="noResult">Company not found.</span>
              </multiselect>
              <span class="help is-info"
                v-if="!isValidCompany">Company is required.</span>
            </div>
          </div>
          <div class="field">
            <div class="label">Subscription History</div>
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :show-pagination="false">
              <tr v-for="(sub, index) in activeSubscription"
                :key="sub.subscriptionid"
                :class="{ 'is-new-item' : sub.isNew }"
                @click="highlightSelected(index, $event)">
                <td class="has-vertical-middle">{{ $filters.formatDateLocale(sub.purchaseDate, $userInfo.locale) }}</td>
                <td>{{ sub.notes }}</td>
                <td class="has-text-right">{{ sub.amount | formatNumber($userInfo.locale) }}</td>
                <td class="has-text-right">{{ sub.previousBalance | formatNumber($userInfo.locale) }}</td>
                <td class="has-text-right">{{ sub.usage | formatNumber($userInfo.locale) }}</td>
                <td class="has-text-right">{{ sub.balance | formatNumber($userInfo.locale) }}</td>
                <td class="has-text-centered is-content-width">
                  <span class="icon"
                    :class="[ sub.active ? 'has-text-success' : 'has-text-danger' ]">
                    <i class="mdi mdi-24px"
                      :class="[ sub.active ? 'mdi-check' : 'mdi-close' ]" />
                  </span>
                </td>
                <td class="has-text-centered is-content-width">
                  <a class="button is-danger is-small is-inverted tooltip"
                    data-tooltip="Delete"
                    @click="deleteSubscription(sub.subscriptionId)">
                    <span class="icon is-medium">
                      <i class="mdi mdi-delete mdi-24px" />
                    </span>
                  </a>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import BulmaTable from '@/components/BulmaTable'
import VueNumeric from '@/components/VueNumeric'
import { SubscriptionColumns } from './columns'
import VehicleLookupService from './VehicleLookupService'
import { NumberFiltersMixin, DateTimeFiltersMixin } from '@/components/mixins/filters'
import { SubscriptionSummaryModel } from '@/classes/viewmodels'
import _orderBy from 'lodash/orderBy'
import Multiselect from 'vue-multiselect'

export default {
  name: 'SubscriptionListing',
  components: {
    BulmaTable,
    VueNumeric,
    Multiselect
  },
  mixins: [NumberFiltersMixin, DateTimeFiltersMixin],
  data: () => {
    return {
      filter: {
        companyId: '',
        sortBy: '',
        sortOrder: '',
        pageSize: 10,
        pageIndex: 1
      },
      entities: null,
      innerValue: null,
      selectedRow: null,
      totalRows: 0,
      isTableLoading: false,
      showAddNew: false,
      // newSubscription: null,
      selectedPurchaseDate: null,
      amount: 0,
      notes: '',
      deleted: [],
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      companyNames: null,
      companyNameFilter: {
        name: '',
        pageIndex: 1,
        pageSize: 20
      },
      selectedCompany: null,
      isCompanyLoading: false
    }
  },
  computed: {
    columns() {
      return SubscriptionColumns
    },
    activeSubscription() {
      return this.innerValue ? this.innerValue.filter(s => !s.deleted) : null
    },
    isValidCompany() {
      return this.selectedCompany && this.$guid.validGuid(this.selectedCompany.companyId)
    }
  },
  watch: {
    selectedCompany: {
      handler: function(newVal, oldVal) {
        if (this.selectedCompany && this.$guid.validGuid(this.selectedCompany.companyId)) {
          this.filter.companyId = this.selectedCompany.companyId
          this.getSubscriptionSummaries()
        } else {
          this.innerValue = null
          this.totalRows = 0
        }
      },
      deep: true
    }
  },
  created() {
    this.filter.companyId = this.$userInfo.companyId
    // this.getSubscriptionSummaries()
    this.getCompanyNames()
  },
  mounted() {
    this.$eventHub.$on('subscription-new', () => {
      this.toggleAddNew()
    })
    this.$eventHub.$on('subscription-save', () => {
      this.save()
    })
  },
  methods: {
    highlightSelected(index, event) {},
    async getSubscriptionSummaries() {
      this.isTableLoading = true
      this.innerValue = await VehicleLookupService.getSubscriptionSummaries(this.filter)
      this.totalRows = this.innerValue.length > 0 ? this.innerValue[0].totalRows : 0
      this.isTableLoading = false
    },
    async save() {
      if (this.innerValue) {
        const newSubscriptions = this.innerValue.filter(s => !s.deleted && s.isNew)
        const deletedSubscriptions = this.innerValue.filter(s => this.deleted.includes(s.subscriptionId))
        const subscriptions = newSubscriptions.concat(deletedSubscriptions)
        // console.log('subscriptions', subscriptions)
        if (subscriptions && subscriptions.length > 0) {
          this.$showSpinner()
          const result = await VehicleLookupService.updateSubscriptions(subscriptions)
          this.getSubscriptionSummaries()
          console.log(result)
          this.$hideSpinner('Updating...')
          // this.$toast.open({
          //   message: 'Subscriptions updated',
          //   type: 'is-success'
          // })
      this.$notification.openNotificationWithType('success','','Subscriptions updated')

        } else {
          // this.$toast.open({
          //   message: 'No changes. Not saved',
          //   type: 'is-warning'
          // })
      this.$notification.openNotificationWithType('warning','','No changes. Not saved')

        }
      } else {
        // this.$toast.open({
        //   message: 'No changes. Not saved',
        //   type: 'is-warning'
        // })
      this.$notification.openNotificationWithType('warning','','No changes. Not saved')

      }
    },
    toggleAddNew() {
      this.showAddNew = !this.showAddNew
      if (this.showAddNew && this.isValidCompany) {
        const utcDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString()
        this.selectedPurchaseDate = new Date(utcDate.split('.')[0] + 'Z')
        this.amount = 0
        this.notes = ''
      } else if (this.showAddNew && !this.isValidCompany) {
        this.showAddNew = false
        // this.$toast.open({
        //   message: 'Please selected a company first',
        //   type: 'is-danger'
        // })
      this.$notification.openNotificationWithType('danger','','Please selected a company first')

      }
    },
    addNewToList() {
      const vm = this
      this.totalRows++
      let previousBalance = 0
      this.innerValue.forEach(function(sub) {
        sub.totalRows = vm.totalRows
        if (sub.active) {
          previousBalance = sub.amount + sub.previousBalance - sub.usage
        }
        sub.active = false
      })
      let subscription = new SubscriptionSummaryModel(
        this.selectedCompany.companyId,
        this.$userInfo.userId,
        this.amount,
        previousBalance,
        this.amount + previousBalance,
        this.notes,
        this.totalRows
      )
      subscription.purchaseDate = this.selectedPurchaseDate.toISOString().split('.')[0] + 'Z'
      // this.innerValue.unshift(subscription)
      this.innerValue.splice(this.innerValue.length, 1, subscription)
      // this.innerValue = _orderBy(this.innerValue, ['subscriptionId, purchaseDate'], ['desc', 'desc'])
      this.innerValue = _orderBy(this.innerValue, ['subscriptionId', 'purchaseDate'], ['desc', 'desc'])
      this.toggleAddNew()
    },
    deleteSubscription(id) {
      const subscription = this.innerValue.find(s => s.subscriptionId === id)
      subscription.deleted = true
      subscription.active = false
      this.deleted.splice(this.deleted.length, 1, subscription.subscriptionId)
    },
    async getCompanyNames(query) {
      this.isCompanyLoading = true
      this.companyNameFilter.name = query
      this.companyNames = await VehicleLookupService.getCompanyNames(
        this.companyNameFilter.name,
        this.companyNameFilter.pageIndex,
        this.companyNameFilter.pageSize
      )
      this.isCompanyLoading = false
    }
    // async selectCompany(selected) {
    //   this.innerValue.assetId = selected.assetId
    //   this.innerValue.assetType = selected.assetType
    //   this.innerValue.invoiceGlCode = selected.assetGlCode
    //   this.assetFilter.assetId = selected.assetId
    //   this.innerValue.contactId = Guid.empty()
    //   this.contacts = await QuickInvoiceService.getContactDropdown(selected.assetId)
    //   this.selectedContact = null
    // },
    // removeCompany(removed) {
    //   this.assetFilter.assetId = Guid.empty()
    //   this.innerValue.assetId = Guid.empty()
    //   this.innerValue.contactId = Guid.empty()
    //   this.innerValue.assetType = null
    //   this.selectedContact = null
    //   this.contacts = []
    //   this.getAssetDropdown()
    // }
  }
}
</script>