import { mapGetters, mapActions } from 'vuex'

const moduleName = 'companysetting'

export default {
  data() {
    return {
      savingStatus: null,
      showSavingStatus: false,
      toastNotificationEnabled: false
    }
  },
  computed: {
    ...mapGetters(moduleName, {
      baseSnapshot: 'baseSnapshot',
      currentSnapshot: 'currentSnapshot',
      hasError: 'hasError',
      saving: 'saving',
      snapshotDiff: 'snapshotDiff',
      snapshots: 'snapshots'
    }),
    ...mapGetters([
      'debounceDelay'
    ])
  },
  watch: {
    hasError(val) {
      if (val) {
        this.$notification.error('','Save Failed')
        // this.$toast.open({
        //   message: 'Save Failed',
        //   type: 'is-danger',
        //   duration: 3000
        // })
      }
    },
    saving(val) {
      if (!this.toastNotificationEnabled) {
        this.showSavingStatus = true
      }
      this.savingStatus = val ? 'Saving ...' : 'Saved'
      !this.hasError && !val && setTimeout(() => {
        this.showSavingStatus = false
      }, 5000)
    }
  },
  methods: {
    ...mapActions(moduleName, ['getStoreItem', 'editStoreItem',
      'clearSnapshots', 'saveSnapshot']),

    openToastNotification() {
      if (!this.hasError) {
        this.$notification.success('','Information has been successfully saved')

        // this.$toast.open({
        //   message: 'Information has been successfully saved',
        //   type: 'is-success',
        //   duration: 3000
        // })
      }
    }
  }
}
