// Based on https://github.com/buefy/buefy
// Date: 15/03/2019
// Version: 0.7.3
import Vue from 'vue'
import BulmaToast from './BulmaToast'

import config from '../utils/config'
import { use, registerComponentProgrammatic } from '../utils/plugins'

const ToastProgrammatic = {
  open(params) {
    let message
    let parent
    let propsData

    if (arguments.length === 1) {
      if (typeof params === 'string') message = params

      const defaultParam = {
        message,
        position: config.defaultToastPosition || 'is-top'
      }
      if (params.parent) {
        parent = params.parent
        delete params.parent
      }
      propsData = Object.assign(defaultParam, params)
    } else if (arguments.length > 1) {
      propsData = {
        message: arguments[1],
        type: `is-${arguments[0]}`,
        position: `is-${arguments[2] || 'top'}`,
        duration: arguments[3] || 3000,
        queue: arguments[4] || false
      }
    }

    const vm = typeof window !== 'undefined' && window.Vue ? window.Vue : Vue
    const ToastComponent = vm.extend(BulmaToast)
    return new ToastComponent({
      parent,
      el: document.createElement('div'),
      propsData
    })
  },
  openType(type, message, position, duration, queue) {
    let parent
    const propsData = {
      message: message,
      type: `is-${type}`,
      position: `is-${position || 'top'}`,
      duration: duration || 3000,
      queue: queue || false
    }
    const vm = typeof window !== 'undefined' && window.Vue ? window.Vue : Vue
    const ToastComponent = vm.extend(BulmaToast)
    return new ToastComponent({
      parent,
      el: document.createElement('div'),
      propsData
    })
  }
}

const Plugin = {
  install(Vue) {
    registerComponentProgrammatic(Vue, '$toast', ToastProgrammatic)
  }
}

use(Plugin)

export default Plugin

export { ToastProgrammatic as BulmaToast }
