<template>
  <quote-builder-part-item-template :is-full-width="isFullWidth"
    class=" pb-2 mb-2 has-text-weight-bold bb-primary-1 has-background-white quote-item-heading">
    <template v-slot:description>
      <div class="is-flex is-align-items-center has-text-weight-bold">
        <span>Parts</span>
        <span class="ml-2">({{ count }})</span>
        <!-- <span class=" ml-5 is-size-6 has-background-primary has-text-white px-2 is-rounded">
          {{ count }}
        </span> -->
      </div>
    </template>
    <template v-slot:part_type>
      <div class="is-flex is-align-items-center mx-2 ">
        <span>Type</span>
      </div>
    </template>

    <template v-slot:report>
      <div class="is-flex is-align-items-center">
        <span>Rpt</span>
      </div>
    </template>

    <template v-slot:part_qty>
      <div class="is-flex is-align-items-center is-justify-content-end ">
        <span>QTY</span>
      </div>
    </template>

    <template v-slot:part_buy>
      <div class="is-flex is-align-items-center is-justify-content-end ">
        <span>Buy Price</span>
      </div>
    </template>

    <template v-slot:part_markup>
      <div class="is-flex is-align-items-center is-justify-content-end ">
        <span>Markup</span>
      </div>
    </template>

    <template v-slot:part_sell>
      <div class="is-flex is-align-items-center is-justify-content-end ">
        <span>Unit Price</span>
      </div>
    </template>

    <template v-slot:total_value>
      <div class="is-flex is-justify-content-end is-align-items-center mx-2 ">
        <span>{{ $filters.formatCurrency(totalAmount, $userInfo.locale, 2) }}</span>
      </div>
    </template>

    <template v-slot:present_as>
      <div class="is-flex is-justify-content-center is-align-items-center">
        <span>Present As</span>
      </div>
    </template>

  </quote-builder-part-item-template>
</template>

<script>
import QuoteBuilderPartItemTemplate from '@/views/quote/quoteBuilder/templates/QuoteBuilderPartItemTemplate.vue'

export default {
  name: 'QuoteBuilderPartHeadingsComponent',
  components: {
    QuoteBuilderPartItemTemplate
  },
  props: {
    isFullWidth: {
      type: Boolean,
      default: true
    },
    count: {
      type: Number,
      default: 0
    },
    totalAmount: {
      type: Number,
      default: 0
    }
  }
  // Your component's logic goes here
}
</script>

<style scoped>
.count-badge {
  margin-left: 5px;
}

/* Your component's styles go here */
</style>