<template>
  <div>

    <div>
      <div class="mb-3"
        style="height: 20px" />
      <div class="columns"
        style="height: 50px">
        <div class="column is-flex is-align-items-center">
          <p class="has-text-info has-text-weight-bold">Suppliers</p>
          <span v-if="isLoadingSuppliers"
            class="ml-2 mb-1 mdi mdi-loading mdi-30px spinner" />
        </div>
        <div class="column is-flex is-justify-content-flex-end">
          <div>
            <p>
              <button class="button is-small is-text tooltip is-tooltip-topright"
                :class="{'disabled': !vehicleMakeId}"
                data-tooltip="Manage Suppliers"
                @click="editSupplers">
                <p class="is-underlined">Show More/Edit</p>
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="is-divider divider-color m-0 mb-2" />

    <span v-if="!vehicleMakeId"
      class="has-text-danger has-text-weight-bold is-size-7">Supplier Section unavaliable, Vehicle Make not Found*
    </span>

    <div class="is-flex is-flex-col">
      <div class="is-flex"
        style="width: 100%">

        <div class="supplier-header"
          style="width: 76%;">
          Preferred Suppliers
        </div>

        <div class="supplier-header"
          style="width: 24%;">
          Type
        </div>
      </div>
    </div>

    <!-- list of preferred suppliers -->
    <div class="scroll-container mb-0"
      style="max-height: 40vh;"
      id="tableContainer1">
      <div v-for="(item, index) in preferredSuppliers"
        :key="item.id + index">
        <div class="is-flex is-flex-col">

          <div class="is-flex table-row"
            :style="{ width: '100%', 'border-left': '4px solid ' + getSupplierTypeColorCode(item.supplierTypeId)}">

            <div style="width: 6%;">
              <div v-if="isNormal"
                class="pretty p-icon p-curve p-smooth m-0 p-bigger">
                <input v-model="item.isChecked"
                  type="checkbox"
                  :checked="item.isChecked"
                  @change="selectOemSupplier(item)">
                <div class="state">
                  <i class="icon mdi mdi-check" />
                  <label />
                </div>
              </div>
              <div v-if="!isNormal"
                class="pretty p-icon p-round p-smooth is-size-10">
                <input v-model="item.isChecked"
                  name="select-supplier"
                  :id="'radio-preferred' + item.id"
                  :value="true"
                  @change="selectSupplier(item)"
                  type="radio">
                <div class="state">
                  <i class="icon mdi mdi-check" />
                  <label />
                </div>
              </div>
            </div>
            <div class=""
              style="width: 70%;">
              {{ item.name }}
            </div>

            <div class=""
              style="width: 24%;">
              {{ item.supplierType }}
            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- list of tier 2 suppliers -->
    <!-- <div class="is-divider custom-divider m-0 mb-2"
        v-if="tier2SupplierList !== null && tier2SupplierList.length > 0" />

      <div class="is-flex is-flex-col"
        v-if="tier2SupplierList !== null && tier2SupplierList.length > 0">
        <div class="is-flex"
          style="width: 100%">

          <div class="supplier-header"
            style="width: 76%;">
            Tier 2 Suppliers
          </div>

          <div class=""
            style="width: 24%;">
            Type
          </div>
        </div>
      </div>
      <div class="scroll-container table-container mb-5"
        id="tableContainer2">
        <div v-for="(item) in innerValue.filter(s=> s.tier=='2')"
          :key="item.id">
          <div class="is-flex is-flex-col">

            <div class="is-flex table-row"
              :style="{ width: '100%', 'border-left': '4px solid ' + getSupplierTypeColorCode(item.supplierTypeId)}">
              <div style="width: 6%;">
                <div v-if="isNormal"
                  class="pretty p-icon p-curve p-smooth m-0 p-bigger">
                  <input v-model="item.isChecked"
                    type="checkbox"
                    :checked="true">
                  <div class="state">
                    <i class="icon mdi mdi-check" />
                    <label />
                  </div>
                </div>

                <div v-if="!isNormal"
                  class="pretty p-icon p-round p-smooth is-size-10">
                  <input v-model="item.isChecked"
                    name="select-supplier"
                    :id="'radio-' + item.id"
                    :value="true"
                    @change="selectSupplier(item)"
                    type="radio">
                  <div class="state">
                    <i class="icon mdi mdi-check" />
                    <label />
                  </div>
                </div>
              </div>
              <div class=""
                style="width: 70%; padding: 5px;">
                {{ item.name }}
              </div>

              <div class=""
                style="width: 24%;">
                {{ item.supplierType }}
              </div>

            </div>
          </div>
        </div>
      </div> -->

    <!-- list of specialist suppliers -->
    <!-- <div class="is-divider custom-divider m-0 mb-2"
        v-if="specialistSupplierList !== null && specialistSupplierList.length >0" />
      <div class="is-flex is-flex-col"
        v-if="specialistSupplierList !== null && specialistSupplierList.length >0">
        <div class="is-flex"
          style="width: 100%">

          <div class="supplier-header"
            style="width: 76%;">
            Specialist Suppliers
          </div>

          <div class="supplier-header"
            style="width: 24%;">
            Type
          </div>
        </div>
      </div> -->

    <!-- <div class="scroll-container table-container mb-5"
        id="tableContainer3">
        <div v-for="(item) in specialistSupplier.filter(s=> s.isSpecial=='1')"
          :key="item.id">
          <div class="is-flex is-flex-col">

            <div class="is-flex table-row"
              :style="{ width: '100%', 'border-left': '4px solid ' + getSupplierTypeColorCode(item.supplierTypeId)}">
              <div style="width: 6%;">
                <div v-if="isNormal"
                  class="pretty p-icon p-curve p-smooth m-0 p-bigger">
                  <input v-model="item.isChecked"
                    type="checkbox"
                    :checked="true">
                  <div class="state">
                    <i class="icon mdi mdi-check" />
                    <label />
                  </div>
                </div>

                <div v-if="!isNormal"
                  class="pretty p-icon p-round p-smooth is-size-10">
                  <input v-model="item.isChecked"
                    name="select-supplier"
                    :id="'radio-' + item.id"
                    :value="true"
                    @change="selectSupplier(item)"
                    type="radio">
                  <div class="state">
                    <i class="icon mdi mdi-check" />
                    <label />
                  </div>
                </div>
              </div>
              <div class=""
                style="width: 70%;">
                {{ item.name }}
              </div>

              <div class=""
                style="width: 24%;">
                {{ item.supplierType }}
              </div>

            </div>
          </div>
        </div>
      </div> -->

    <!-- list of selected suppliers -->
    <div class="is-divider custom-divider m-5" />
    <div class="is-flex is-flex-col">
      <div class="is-flex"
        style="width: 100%">
        <div class="supplier-header"
          style="width: 76%; color: #54B700">
          Selected Suppliers ({{ selectedSuppliers.length }} / {{ isNormal ? maxSuppliersCount : 1 }})
        </div>

        <div class="supplier-header"
          style="width: 24%; color: #54B700">
          Type
        </div>
      </div>
    </div>

    <div class="scroll-container table-container mb-3"
      style="max-height: 40vh;"
      id="tableContainer3">
      <div v-for="(item) in selectedSuppliers"
        :key="item.id">
        <div class="is-flex is-flex-col">

          <div class="is-flex table-row"
            :style="{ width: '100%', border:'1px solid #54B700', 'border-left': '4px solid ' + getSupplierTypeColorCode(item.supplierTypeId)}">
            <div style="width: 6%;">
              <div v-if="isNormal"
                class="pretty p-icon p-curve p-smooth m-0 p-bigger">
                <input v-model="item.isChecked"
                  type="checkbox"
                  :checked="true"
                  @change="refreshSelectedSuppliers()">
                <div class="state">
                  <i class="icon mdi mdi-check" />
                  <label />
                </div>
              </div>

              <div v-if="!isNormal"
                class="pretty p-icon p-round p-smooth is-size-10">
                <input v-model="item.isChecked"
                  name="selected-supplier"
                  :id="'radio-selected' + item.id"
                  :value="true"
                  @change="selectSupplier(item)"
                  type="radio">
                <div class="state">
                  <i class="icon mdi mdi-check" />
                  <label />
                </div>
              </div>
            </div>
            <div class=""
              style="width: 70%;">
              {{ item.name }}
            </div>

            <div class=""
              style="width: 24%;">
              {{ item.supplierType }}
            </div>
            <span class="icon">
              <i class="mdi mdi-check" />
            </span>
          </div>
        </div>
      </div>
    </div>

    <parts-check-suppliers-modal v-if="isPartsCheckSuppliersModalActive && innerValue"
      v-model="innerValue"
      :preferred-suppliers="preferredSuppliers"
      :active.sync="isPartsCheckSuppliersModalActive"
      :states="states"
      :vehicle-make-id="vehicleMakeId"
      :state-id="stateId"
      :max-suppliers-count="maxSuppliersCount"
      @changeState="changeState"
      @update-preferred-suppliers="onUpdatePreferredSuppliers"
      @close="isPartsCheckSuppliersModalActive = false"
      @selected-other-supplier-list="selectedOtherSupplierList"
      @refresh-suppliers-list="refreshSuppliersList"
      :is-normal="isNormal" />

  </div>
</template>

<script>
import { PartsCheckPreferredSuppliersColumns, PartsCheckTier2SuppliersColumns, PartsCheckSpecialistSuppliersColumns } from '../../columns'
import PartsCheckSuppliersModal from './PartsCheckSuppliersModal'
import { PartsCheckService } from '../../services'
import cloneDeep from 'lodash.clonedeep'
// import Circle8 from '@/components/Spinners/Circle8.vue'

export default {
  name: 'PartsSuppliers',
  components: {
    PartsCheckSuppliersModal
  },
  props: {
    value: null,
    states: null,
    vehicleMakeId: null,
    preferredSuppliersProp: null,
    isNormal: true,
    specialistSupplier: null,
    maxSuppliersCount: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      preferredSuppliers: [],
      tier2SupplierList: null,
      specialistSupplierList: null,
      isPartsCheckSuppliersModalActive: false,
      innerValue: null,
      stateId: null,
      selectedOtherSuppliers: [],
      supplierTypeColor: '',
      colorCode: '',
      selectedSuppliers: [],
      isLoadingSuppliers: false
    }
  },
  computed: {
    preferredSuppliersColumns() {
      return PartsCheckPreferredSuppliersColumns
    },
    tier2SuppliersColumns() {
      return PartsCheckTier2SuppliersColumns
    },
    specialistSuppliersColumns() {
      return PartsCheckSpecialistSuppliersColumns
    },
    selectedIds() {
      return this.selectedSuppliers.map((i) => i.id)
    },
    selectedCount() {
      return this.preferredSuppliers.filter((i) => i.isChecked).length
    }
  },
  watch: {
    isNormal: {
      handler: function (newVal, oldVal) {
        if (this.isNormal) {
          this.handlePreferredSuppliers()
        } else {
          this.preferredSuppliers.forEach((i) => (i.isChecked = false))
          this.innerValue.forEach((i) => (i.isChecked = false))
          if (!!this.preferredSuppliers.length) {
            this.preferredSuppliers[0].isChecked = true
          }
        }
        this.getSelectedSuppliers()
        this.refreshSelectedSuppliers()
      },
      deep: true
    },
    preferredSuppliersProp: {
      handler: function (newVal, oldVal) {
        this.preferredSuppliers = cloneDeep(newVal).map((i) => ({ ...i, isChecked: false }))

        this.$nextTick(() => {
          this.handlePreferredSuppliers()
        })
      }
    },
    value: {
      handler: function (newVal, oldVal) {
        this.innerValue = cloneDeep(newVal)
      },
      deep: true
    }
  },
  created() {
    this.innerValue = cloneDeep(this.value)
    this.stateId = this.getStateId()
    this.preferredSuppliers = cloneDeep(this.preferredSuppliersProp).map((i) => ({ ...i, isChecked: false }))
    if (this.innerValue) {
      this.getPreferredSupplierList()
      this.getTier2SupplierList()
      this.getSpecialistSupplierList()
    }

    this.handlePreferredSuppliers(true)
  },
  methods: {
    async onUpdatePreferredSuppliers(value) {
      this.isLoadingSuppliers = true

      // get items that exist in value but don't exist in preferredSuppliers
      const valueSet = new Set(value.map((item) => item.id))
      const preferredSet = new Set(this.preferredSuppliers.map((item) => item.id))
      const newItems = value.filter((item) => !preferredSet.has(item.id))
      // get items that exist in preferredSuppliers but don't exist in value
      const removedItems = this.preferredSuppliers.filter((item) => !valueSet.has(item.id))
      const removePromises = removedItems.map((item) => PartsCheckService.removeFromPreferredSuppliersList(item.id, this.vehicleMakeId))
      const addPromises = newItems.map((item) => PartsCheckService.addPreferredSuppliersList(item.id, this.vehicleMakeId))
      await Promise.all([...removePromises, ...addPromises])
      this.refreshSuppliersList()

      this.isLoadingSuppliers = false
    },
    changeState(id) {
      this.stateId = id
      this.$nextTick(() => {
        this.getStateSupplierList()
      })
    },

    handlePreferredSuppliers(isFirstLoad = false) {
      // only select preferred suppliers if it is the first load, otherwise keep the selected suppliers, even if empty list
      if (!isFirstLoad) {
        const selectedSupplierIds = this.selectedSuppliers.filter((i) => i.isChecked && i.isPreferred == '1').map((i) => i.id)
        this.preferredSuppliers.forEach((i) => {
          if (selectedSupplierIds.includes(i.id)) {
            i.isChecked = true
          }
        })
        this.$nextTick(() => {
          this.getSelectedSuppliers()
        })
        return
      }

      // when price parts is first opened
      this.preferredSuppliers.forEach((i) => (i.isChecked = false))
      let totalChecked = 0
      const firstOemSupplier = this.preferredSuppliers.find((i) => i.supplierTypeId == '3')
      this.preferredSuppliers.forEach((i) => {
        if ((totalChecked < this.maxSuppliersCount && i.supplierTypeId !== '3') || i.id == firstOemSupplier?.id) {
          i.isChecked = true
          totalChecked++
        } else i.isChecked = false
      })

      this.$nextTick(() => {
        this.getSelectedSuppliers()
      })
    },

    getSupplierTypeColorCode(value) {
      if (value === '3') return '#2e87ec'
      else if (value === '4') return '#7700ff'
      else if (value === '5') return '#13bb38'
      else if (value === '6') return '#cf9c0f'
      else return '#2e87ec'
    },
    selectSupplier(item) {
      if (item.isChecked) {
        for (let i = 0; i < this.preferredSuppliers.length; i++) {
          if (item.id == this.preferredSuppliers[i].id) {
            this.preferredSuppliers[i].isChecked = true
          } else this.preferredSuppliers[i].isChecked = false
        }

        for (let i = 0; i < this.innerValue.length; i++) {
          if (item.id == this.innerValue[i].id) {
            this.innerValue[i].isChecked = true
          } else this.innerValue[i].isChecked = false
        }
        this.getSelectedSuppliers()
        this.refreshSelectedSuppliers()
      }
    },
    async supplierList() {
      this.$emit('supplier-list', this.selectedOtherSuppliers)
    },
    async selectedOtherSupplierList(value) {
      const valueSet = new Set(value.map((item) => item.id))

      this.innerValue.forEach((item) => {
        if (valueSet.has(item.id)) {
          item.isChecked = true
        } else {
          item.isChecked = false
        }
      })

      this.preferredSuppliers.forEach((item) => {
        if (valueSet.has(item.id)) {
          item.isChecked = true
        } else {
          item.isChecked = false
        }
      })
      this.$nextTick(() => {
        this.getSelectedSuppliers()
        this.refreshSelectedSuppliers()
      })
    },
    getPreferredSupplierList() {
      this.preferredSupplierList = this.innerValue.filter((s) => s.isPreferred === '1')
    },
    getTier2SupplierList() {
      this.tier2SupplierList = this.innerValue.filter((s) => s.tier === '2')
    },
    getSpecialistSupplierList() {
      this.specialistSupplierList = this.innerValue.filter((s) => s.isSpecial === '1')
    },
    editSupplers() {
      this.isPartsCheckSuppliersModalActive = true
    },
    getStateId() {
      return this.states.find((s) => s.nameShort == this.$company.info.stateCode).id
    },
    async getStateSupplierList() {
      var allSuppliers = await PartsCheckService.getSuppliers(this.stateId, this.vehicleMakeId)
      this.innerValue = allSuppliers
    },
    refreshSuppliersList() {
      this.$emit('get-supplier-list')
    },
    selectOemSupplier(item) {
      // check if selected suppliers has an OEM supplier
      const hasOemSupplier = this.selectedSuppliers.find((i) => i.supplierTypeId == '3')

      if (item.isChecked) {
        if (hasOemSupplier && item.supplierTypeId == '3') {
          hasOemSupplier.isChecked = false
        } else if (this.selectedSuppliers.length >= this.maxSuppliersCount) {
          // this.$toast.open({
          //   message: 'You can only select up to 10 suppliers',
          //   type: 'is-danger',
          //   position: 'is-top',
          //   duration: 5000,
          //   queue: false
          // })
          this.$notification.openNotificationWithType('danger','','You can only select up to 10 suppliers',5000)

          this.$nextTick(() => {
            item.isChecked = false
          })
        }
      }

      this.$nextTick(() => {
        this.getSelectedSuppliers()
      })
    },
    getSelectedSuppliers() {
      const selectedSuppliers = []

      this.preferredSuppliers?.forEach((i) => {
        if (!!i.isChecked) {
          selectedSuppliers.push(i)
        }
      })

      this.innerValue?.forEach((i) => {
        if (!!i.isChecked) {
          selectedSuppliers.push(i)
        }
      })

      this.selectedSuppliers = selectedSuppliers
      this.$emit('get-selected-suppliers', selectedSuppliers)
    },
    refreshSelectedSuppliers() {
      this.selectedSuppliers = this.selectedSuppliers.filter((supplier) => supplier.isChecked == true)
      this.$emit('get-selected-suppliers', this.selectedSuppliers)
    }
  }
}
</script>

<style lang="scss" scoped>
// keep rotating spinner in the center of the page
.spinner {
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.table-container {
  overflow-y: auto;
}
.fixed-size-div {
  height: 160px;
}
.divider-color {
  border-color: #2196f3;
}
.table-row {
  display: flex;
  background-color: white;
  border: 1px solid #dce1e5;
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 5px !important;
  padding: 5px;
  font-size: 14px;
}

.item-type-header {
  text-decoration: underline;
  text-decoration-skip-ink: none;
  text-decoration-thickness: 3px;
  padding: 5px;
  font-weight: bold;
  font-size: 14px;
}
.scroll-container {
  overflow-y: auto;
}
.custom-divider {
  border-color: #a8abacda;
}
.supplier-header {
  padding: 5px;
  font-weight: bold;
  text-align: left;
}
.supplier-list {
  padding: 5px;
}
</style>